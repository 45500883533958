import { css } from "@emotion/react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { FC, ReactNode, useState } from "react";
import { mediaQueries, breakpoints } from "../styles/mediaqueries";
import { color, text } from "../styles/theme";

type Props = {
  title: string;
  link: string;
  children: ReactNode;
};
export const FooterAccordion: FC<Props> = ({ title, link, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const matches: boolean = useMediaQuery(
    "(min-width:" + breakpoints.lg + "px)"
  );

  return (
    <div css={accordion}>
      {matches ? (
        <>
          <a href={link}>{title}</a>
        </>
      ) : (
        <button css={accordionWrapper} onClick={() => setIsOpen(!isOpen)}>
          {title}
          <span css={accordionIcon}>
            {!isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </span>
        </button>
      )}
      {matches ? (
        <nav css={accordionItem}>{children}</nav>
      ) : (
        !isOpen && <nav css={accordionItem}>{children}</nav>
      )}
    </div>
  );
};

const accordion = css`
  border-bottom: 1px solid ${color.slate[0]};
  padding: 16px 0;

  ${mediaQueries("lg")} {
    border: none;
  } ;
`;

const accordionWrapper = css`
  align-items: center;
  width: 100%;
  font-size: ${text.xs};
  display: flex;
  justify-content: space-between;

  ${mediaQueries("lg")} {
    font-size: ${text.base};
    margin-bottom: 8px;
    padding: 0;
  } ;
`;

const accordionIcon = css`
  color: ${color.edesuYellow[400]};
  height: 20px;
  width: 20px;
`;

const accordionItem = css`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  line-height: 2;

  ${mediaQueries("lg")} {
  } ;
`;

// const  = css`

// `;

// const  = css`

// `;
