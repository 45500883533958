import { css } from "@emotion/react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { InformationNav } from "../components/InformationNav";
import { Layout } from "../components/Layout";
import { SimulationModal } from "../components/SimulationModal";
import { FormValues, initFormValues } from "../entrypoints/CreditCard";
import fv from "../images/common/fv.jpg";
import { mediaQueries, breakpoints } from "../styles/mediaqueries";
import { color, text } from "../styles/theme";

export const Index: FC = () => {
  const formMethods = useForm<FormValues>({
    defaultValues: initFormValues,
    mode: "onBlur",
    shouldFocusError: true,
  });

  const isPC: boolean = useMediaQuery("(min-width:" + breakpoints.lg + "px)");
  const {
    formState: { isSubmitted },
  } = formMethods;

  return (
    <Layout>
      <FormProvider {...formMethods}>
        <div css={container}>
          {/*シミュレーション入力前SP*/}
          {!isPC && !isSubmitted && (
            <>
              <img
                css={main}
                src={fv}
                alt="イーデス"
                width={375}
                height={422}
              />
            </>
          )}

          <div css={block}>
            {/*シミュレーション入力前PC*/}
            {isPC && (
              <div>
                <h2 css={title}>ポイント還元率シミュレーション</h2>
                <p css={copy}>
                  各お店での利用金額を入力して、各クレジットカードいくらポイントが貯まるかチェック！
                </p>
              </div>
            )}

            {/*シミュレーション入力後SP*/}
            {!isPC && isSubmitted && (
              <>
                <h2 css={title}>シミュレーション結果</h2>
                <SimulationModal
                  button="secondary"
                  label="再度シミュレーションする"
                />
              </>
            )}
          </div>

          <div css={inner}>
            <InformationNav />
          </div>
        </div>
      </FormProvider>
    </Layout>
  );
};

const container = css`
  background: ${color.slate[50]};
  padding: 16px 0 0;

  ${mediaQueries("lg")} {
    background: ${color.slate[0]};
    padding: 16px 16px 0;
  } ;
`;

const block = css`
  padding: 16px 16px 0;

  ${mediaQueries("lg")} {
    margin: auto;
    padding: 0;
    width: 1024px;
  } ;
`;

const inner = css`
  ${mediaQueries("lg")} {
    margin: auto;
    width: 1024px;
  } ;
`;

const main = css`
  width: 100%;
  height: auto;
  ${mediaQueries("lg")} {
    display: none;
  } ;
`;

const title = css`
  font-size: ${text["2xl"]};
  font-weight: bold;
`;

const copy = css`
  font-size: ${text.xs};
  margin-top: 8px;
`;
