import { FormValues } from "../entrypoints/CreditCard";

export const amountFormat = (amount: number): string => {
  return String(Math.floor(amount)).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
};

export const calcTotalUserAmount = (
  category: FormValues["category"]
): number => {
  return (
    category.reduce((sum, category) => {
      return (
        sum +
        category.stores.reduce((sum, store) => {
          return sum + (isNaN(store.userprice) ? 0 : store.userprice);
        }, 0 as number)
      );
    }, 0 as number) * 12
  );
};

export const calcTotalCardAmounts = (
  category: FormValues["category"]
): { [key: number]: number } => {
  return category.reduce((cardSums, category) => {
    category.stores.map((store) => {
      store.returnRate.map((card) => {
        cardSums[card.cardid] =
          (cardSums[card.cardid] || 0) +
          (isNaN(store.userprice) ? 0 : store.userprice) * card.rate * 12;
      });
    });
    return cardSums;
  }, {} as { [key: number]: number });
};

export const calcTotalCardScores = (calcTotalCardAmounts: {
  [key: number]: number;
}): { [key: number]: string } => {
  const max = Object.values(calcTotalCardAmounts).reduce((m, a) => {
    return m > a ? m : a;
  }, 0);
  const min = Object.values(calcTotalCardAmounts).reduce((m, a) => {
    return m < a ? m : a;
  }, Infinity);
  return Object.fromEntries(
    Object.entries(calcTotalCardAmounts).map(([k, v]) => {
      if (v < max - ((max - min) * 3) / 4) {
        return [k, "bad"];
      } else if (max - ((max - min) * 3) / 4 < v && v < max - (max - min) / 2) {
        return [k, "notGood"];
      } else if (max - (max - min) / 2 < v && v < max - (max - min) / 4) {
        return [k, "normal"];
      } else {
        return [k, "good"];
      }
    })
  );
};

export const calcTotalCardStoreAmounts = (
  category: FormValues["category"]
): { [key: number]: { [key: number]: number } } => {
  return category.reduce((cardStoreSums, category) => {
    category.stores.map((store) => {
      store.returnRate.map((card) => {
        if (!cardStoreSums[card.cardid]) {
          cardStoreSums[card.cardid] = [];
        }
        cardStoreSums[card.cardid][store.storeid] =
          (cardStoreSums[card.cardid][store.storeid] || 0) +
          store.userprice * card.rate * 12;
      });
    });
    return cardStoreSums;
  }, {} as { [key: number]: { [key: number]: number } });
};

export const calcAvgCardRates = (
  category: FormValues["category"]
): { [key: number]: number } => {
  const cardRates = category.reduce((cardRates, category) => {
    category.stores.map((store) => {
      store.returnRate.map((card) => {
        if (!cardRates[card.cardid]) {
          cardRates[card.cardid] = [];
        }
        cardRates[card.cardid].push(card.rate);
      });
    });
    return cardRates;
  }, {} as { [key: number]: number[] });
  return Object.entries(cardRates).reduce((cardAvgRates, [id, rates]) => {
    cardAvgRates[Number(id)] =
      rates.reduce((t, r) => {
        return t + r;
      }, 0) / rates.length;
    return cardAvgRates;
  }, {} as { [key: number]: number });
};
