import { css } from "@emotion/react";
import { FC } from "react";
import { Layout } from "../components/Layout";
import { mediaQueries } from "../styles/mediaqueries";
import { color, text } from "../styles/theme";

export const Error404: FC = () => {
  return (
    <Layout>
      <div css={container}>
        <div css={block}>
          <h2 css={title}>
            404
            <br />
            ERROR
          </h2>
          <p css={copy}>お探しのページが見つかりませんでした...</p>

          <a href="/" css={link}>
            トップページへ
          </a>
        </div>
      </div>
    </Layout>
  );
};

const container = css`
  padding: 32px 0 64px;

  ${mediaQueries("lg")} {
    padding: 48px 0 96px;
  } ;
`;

const block = css`
  max-width: 672px;
  margin: auto;
  text-align: center;

  ${mediaQueries("lg")} {
    border: 1px solid ${color.slate[300]};
    padding: 32px;
  } ;
`;

const title = css`
  font-size: 64px;
  font-weight: 800;
  line-height: 1;
`;

const copy = css`
  font-size: ${text.base};
  margin-top: 24px;
`;

const link = css`
  color: ${color.blue[600]};
  display: inline-block;
  font-size: ${text.base};
  margin-top: 24px;
  text-decoration: underline;
`;
