import { css } from "@emotion/react";
import { FC } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { color, text } from "../styles/theme";

type Props = JSX.IntrinsicElements["input"] & {
  // TFieldNameが動的に決まるのでany以外指定できない
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegisterReturn<any>;
};

export const InputTypeNumber: FC<Props> = ({ placeholder, register }) => {
  return (
    <input
      type="number"
      min="0"
      pattern="^([1-9][0-9]*|0)$"
      css={baseStyle(register.name)}
      placeholder={placeholder}
      {...register}
    />
  );
};

const baseStyle = (value: string) => css`
  background-color: ${color.slate[0]};
  border: 1px solid ${value ? color.blue[600] : color.divider.onSurface};
  border-radius: 8px;
  font-size: ${text.base};
  padding: 8px;
  resize: none;
  width: 100%;
  line-height: 2;
`;
