import { css } from "@emotion/react";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import StarIcon from "@material-ui/icons/Star";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../atoms/Button";
import {
  cardNames,
  FormValues,
  FormValuesStore,
  storeNames,
} from "../entrypoints/CreditCard";
import {
  amountFormat,
  calcAvgCardRates,
  calcTotalCardAmounts,
  calcTotalCardScores,
  calcTotalCardStoreAmounts,
} from "../libs/Calculator";
import { color, text } from "../styles/theme";

type Props = {
  scrollToCard: (cardid: number) => void;
};

export const CardList: FC<Props> = ({ scrollToCard }) => {
  const { watch } = useFormContext<FormValues>();
  const categories = watch("category");
  const stores = storeNames.reduce((stores, storeCategory) => {
    return stores.concat(storeCategory.stores);
  }, [] as FormValuesStore[]);

  const totalCardAmounts = calcTotalCardAmounts(categories);
  const totalCardScores = calcTotalCardScores(totalCardAmounts);
  const totalCardStoreAmounts = calcTotalCardStoreAmounts(categories);
  const avgCardRates = calcAvgCardRates(categories);

  const [offset, setOffset] = useState(0);
  const perPage = 5;

  const handlePageChange = (data: { [x: string]: number }) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const page_number = data["selected"];
    setOffset(page_number * perPage);
  };

  const getEvaluationScoreText = (score: string): string => {
    const key = score as keyof typeof evaluationScoreTexts;
    return evaluationScoreTexts[key] ? evaluationScoreTexts[key] : "-";
  };

  return (
    <>
      <div css={container}>
        {cardNames.slice(offset, offset + perPage).map((card) => (
          <div css={cardView} key={card.cardid}>
            <div css={cardItem}>
              <div css={cardTitle}>
                <img src={card.image} alt="" css={cardImg} />
                <div css={cardInfo}>
                  <h2 css={cardName}>{card.name}</h2>
                  {/*
                  <p css={score}>
                    <StarIcon /> {card.star}
                  </p>
                  */}
                </div>
              </div>
              <div css={evaluation}>
                <div css={evaluationItem}>
                  <p css={evaluationName}>評価</p>
                  <p css={evaluationScore(totalCardScores[card.cardid])}>
                    {getEvaluationScoreText(totalCardScores[card.cardid])}
                  </p>
                </div>
                <div css={evaluationItem}>
                  <p css={evaluationName}>貯まるポイント</p>
                  <p css={evaluationText}>
                    {amountFormat(totalCardAmounts[card.cardid] || 0)}
                    <small>円分/年</small>
                  </p>
                </div>
                <div css={evaluationItem}>
                  <p css={evaluationName}>年会費</p>
                  <p css={evaluationText}>
                    {amountFormat(card.annualfee)}
                    <small>円</small>
                  </p>
                </div>
              </div>
              <div css={point}>
                <div css={pointBlock}>
                  <h3 css={pointTitle}>ポイント内訳(上位3件)</h3>
                  <Link
                    to="#"
                    onClick={() => {
                      scrollToCard(card.cardid);
                    }}
                    css={moreLink}
                  >
                    内訳一覧 <KeyboardArrowRightIcon />
                  </Link>
                </div>
                <ul css={pointList}>
                  {stores
                    .sort((a, b) => {
                      const at =
                        totalCardStoreAmounts[card.cardid][a.storeid] || 0;
                      const bt =
                        totalCardStoreAmounts[card.cardid][b.storeid] || 0;
                      if (at == bt) return a.storeid > b.storeid ? 1 : -1;
                      return at > bt ? -1 : 1;
                    })
                    .slice(0, 3)
                    .map((store) => (
                      <li css={pointListItem} key={store.storeid}>
                        <p css={company}>{store.name}</p>
                        <p css={price}>
                          {amountFormat(
                            totalCardStoreAmounts[card.cardid][store.storeid] ||
                              0
                          )}
                          <small>円分/年</small>
                        </p>
                      </li>
                    ))}
                </ul>
              </div>
              <div css={buttonArea}>
                <SecondaryButton css={[button, detailbtn]}>
                  <a
                    href={card.detailurl}
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >
                    詳細を見る
                  </a>
                </SecondaryButton>
                <SecondaryButton css={button}>
                  <a
                    href={card.cvurl}
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >
                    公式サイト
                  </a>
                </SecondaryButton>
              </div>
            </div>
          </div>
        ))}
        <ReactPaginate
          css={pagination}
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          pageCount={Math.ceil(cardNames.length / perPage)} // 全部のページ数。端数の場合も考えて切り上げに。
          marginPagesDisplayed={2} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
          pageRangeDisplayed={5} // アクティブなページを基準にして、そこからいくつページ数を表示するか
          onPageChange={handlePageChange} // クリック時のfunction
          activeClassName={"active"} // アクティブなページのliに着くクラス名
          previousClassName={"paginationPrevious"} // 「<」のliに着けるクラス名
          nextClassName={"paginationNext"} // 「>」のliに着けるクラス名
          disabledClassName={"paginationDisabled"} // 使用不可の「<,>」に着くクラス名
        />
      </div>
    </>
  );
};

const evaluationScoreTexts = {
  good: "◎",
  normal: "○",
  notGood: "▲",
  bad: "×",
};

const container = css`
  background: ${color.slate[100]};
  padding: 16px;
`;

const cardView = css`
  background: ${color.slate[0]};
  border: 1px solid ${color.slate[300]};
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 16px;
`;

const cardItem = css``;

const cardTitle = css`
  display: flex;
  gap: 8px;
`;

const cardImg = css`
  width: 60px;
  height: 35px;
  object-fit: cover;
`;

const cardInfo = css``;

const cardName = css`
  font-size: ${text.base};
  font-weight: bold;
`;

const score = css`
  align-items: center;
  display: flex;
  font-size: ${text.xs};
  font-weight: bold;

  svg {
    height: ${text.sm};
    width: ${text.sm};
    color: ${color.edesuYellow[400]};
  }
`;

const evaluation = css`
  align-items: center;
  background: ${color.slate[100]};
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
`;

const evaluationItem = css`
  padding: 8px 6px;
  text-align: center;
  width: calc((100% - 16px) / 3);
`;

const evaluationName = css`
  font-size: ${text.xs};
  color: ${color.slate[500]};
`;

const evaluationText = css`
  font-size: ${text.lg};
  font-weight: bold;
  line-height: 1;
  margin-top: 4px;

  small {
    font-size: ${text.xs};
    font-weight: normal;
  }
`;

const evaluationScore = (isColor: string) => css`
  ${isColor === "good" && "color:" + color.red[600] + ";"}
  ${isColor === "bad" && "color:" + color.blue[600] + ";"}
  ${isColor === "notGood" && "color:" + color.slate[800] + ";"}
  ${isColor === "normal" && "color:" + color.slate[800] + ";"}
  font-size: ${text.lg};
  font-weight: bold;
  line-height: 1;
  margin-top: 4px;
`;

const point = css`
  font-size: ${text.base};
  font-weight: bold;

  small {
    font-size: ${text.xs};
    font-weight: normal;
  }
`;

const pointBlock = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const pointTitle = css`
  font-size: ${text.base};
  font-weight: bold;
`;

const moreLink = css`
  align-items: center;
  color: ${color.blue[600]};
  display: flex;
  font-size: ${text.sm};

  svg {
    font-size: ${text.sm};
  }
`;

const pointList = css`
  margin-top: 8px;
`;

const pointListItem = css`
  align-items: center;
  border-bottom: 1px solid ${color.slate[300]};
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
`;

const company = css`
  font-size: ${text.xs};
  width: calc(65% - 8px);
`;

const price = css`
  font-size: ${text.base};
  text-align: right;
  width: 35%;

  small {
    font-size: ${text.xs};
  }
`;

const pagination = css`
  display: flex;
  justify-content: center;
  font-size: ${text.xs};

  > li {
    height: 40px;
    width: 40px;
    border: 1px solid ${color.slate[300]};
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${color.slate[0]};
  }

  .active {
    background: ${color.slate[800]};
    color: ${color.slate[0]};
  }
`;

const buttonArea = css`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const button = css`
  width: calc(100%);
`;

const detailbtn = css`
  margin-right: 5px;
`;

/*
const button = css`
  width: calc((100% - 16px) / 2);
`;
*/
