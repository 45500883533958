import { css } from "@emotion/react";
import Drawer from "@material-ui/core/Drawer";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import { FC, useState } from "react";
import logo from "../images/common/logo.svg";
import { mediaQueries, breakpoints } from "../styles/mediaqueries";
import { color, text } from "../styles/theme";

export const Header: FC = () => {
  const matches: boolean = useMediaQuery(
    "(min-width:" + breakpoints.lg + "px)"
  );
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const contents = () => (
    <div role="presentation" css={drawerWrapper}>
      <div css={drawerHeader}>
        <button
          type="button"
          css={closeButton}
          onClick={() => setOpenDrawer(false)}
        >
          <CloseIcon />
        </button>
      </div>
      <nav css={navList}>
        <a href="https://www.a-tm.co.jp/top/corporate/" css={navItem}>
          運営情報
        </a>
        <a href="https://www.a-tm.co.jp/top/contents_policy/" css={navItem}>
          コンテンツポリシー
        </a>
        <a
          href="https://finance.a-tm.co.jp/brand"
          target="_blank"
          rel="noopener nofollow noreferrer"
          css={navItem}
        >
          イーデスについて
        </a>

        {matches ? (
          <>
            <button type="button" onClick={handleClick} css={navItem}>
              記事一覧
            </button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <a href="https://www.a-tm.co.jp/top/creditcard/list/">
                  転職の記事一覧
                </a>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <a href="https://www.a-tm.co.jp/top/creditcard/list/">
                  クレジットカードの記事一覧
                </a>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <a href="https://www.a-tm.co.jp/top/cardloan/list/">
                  カードローンの記事一覧
                </a>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <a href="https://www.a-tm.co.jp/top/housingloan/list/">
                  住宅ローンの記事一覧
                </a>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <a href="https://www.a-tm.co.jp/top/securities/list/">
                  証券投資の記事一覧
                </a>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <a href="https://www.a-tm.co.jp/top/hikkoshi/list/">
                  引越しの記事一覧
                </a>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <a href="https://www.a-tm.co.jp/top/car/list/">
                  自動車の記事一覧
                </a>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <a href="https://www.a-tm.co.jp/top/energy/list/">
                  電気・ガスの記事一覧
                </a>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <a href="https://www.a-tm.co.jp/top/survey/list/">
                  調査リリースの記事一覧
                </a>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <a href="https://www.a-tm.co.jp/top/furusato-tax/list/">
                  ふるさと納税の記事一覧
                </a>
              </MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <a href="https://www.a-tm.co.jp/top/creditcard/list/" css={navItem}>
              転職の記事一覧
            </a>
            <a href="https://www.a-tm.co.jp/top/creditcard/list/" css={navItem}>
              クレジットカードの記事一覧
            </a>
            <a href="https://www.a-tm.co.jp/top/cardloan/list/" css={navItem}>
              カードローンの記事一覧
            </a>
            <a
              href="https://www.a-tm.co.jp/top/housingloan/list/"
              css={navItem}
            >
              住宅ローンの記事一覧
            </a>
            <a href="https://www.a-tm.co.jp/top/securities/list/" css={navItem}>
              証券投資の記事一覧
            </a>
            <a href="https://www.a-tm.co.jp/top/hikkoshi/list/" css={navItem}>
              引越しの記事一覧
            </a>
            <a href="https://www.a-tm.co.jp/top/car/list/" css={navItem}>
              自動車の記事一覧
            </a>
            <a href="https://www.a-tm.co.jp/top/energy/list/" css={navItem}>
              電気・ガスの記事一覧
            </a>
            <a href="https://www.a-tm.co.jp/top/survey/list/" css={navItem}>
              調査リリースの記事一覧
            </a>
            <a
              href="https://www.a-tm.co.jp/top/furusato-tax/list/"
              css={navItem}
            >
              ふるさと納税の記事一覧
            </a>
          </>
        )}
      </nav>
    </div>
  );

  return (
    <>
      <header css={header}>
        <div css={container}>
          <a href="/">
            {matches ? (
              <img src={logo} alt="イーデス" width={120} height={40} />
            ) : (
              <img src={logo} alt="イーデス" width={100} height={32} />
            )}
          </a>
          {matches ? (
            <>{contents()}</>
          ) : (
            <>
              <Drawer
                anchor="right"
                open={openDrawer}
                onClose={() => {
                  setOpenDrawer(false);
                }}
              >
                {contents()}
              </Drawer>
            </>
          )}
        </div>
      </header>
    </>
  );
};

const header = css`
  align-items: center;
  height: 64px;
  display: flex;

  ${mediaQueries("lg")} {
    height: 100px;
  } ;
`;

const container = css`
  align-items: center;
  color: ${color.slate[800]};
  display: flex;
  font-size: ${text.sm};
  justify-content: space-between;
  margin: auto;
  padding: 16px;
  width: 100%;

  ${mediaQueries("lg")} {
    max-width: 1024px;
    padding: 0;
  } ;
`;

const navList = css`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  padding: 24px;
  gap: 24px 0;

  ${mediaQueries("lg")} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: normal;
    gap: 0 24px;
    max-width: 1024px;
    padding: 0;
  } ;
`;

const navItem = css``;

const hamburgerButton = css`
  align-items: center;
  background: ${color.slate[800]};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 32px;
  width: 32px;

  svg {
    color: ${color.slate[0]};
  }
`;
const drawerWrapper = css`
  padding: 24px 16px;
  width: 100vw;

  ${mediaQueries("lg")} {
    padding: 0;
    width: auto;
  } ;
`;

const drawerHeader = css`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  margin-bottom: 24px;

  ${mediaQueries("lg")} {
    display: none;
  } ;
`;

const closeButton = css`
  svg {
    color: ${color.slate[800]};
    height: 24px;
    width: 24px;
  }
`;
