import { Control, useWatch } from "react-hook-form";
import { FormValues } from "../entrypoints/CreditCard";
import { amountFormat } from "../libs/Calculator";

type Props = {
  control: Control<FormValues>;
  rate: number;
  name: `category.${number}.stores.${number}.userprice`;
};

// 計算結果を表示させる TotalAmount コンポーネント
export const TableRate = ({ control, rate, name }: Props): JSX.Element => {
  const formValues = useWatch({
    name: name,
    control,
  });

  return <>{amountFormat((isNaN(formValues) ? 0 : formValues) * rate * 12)}</>;
};
