import { css } from "@emotion/react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import LaunchIcon from "@material-ui/icons/Launch";
import StarIcon from "@material-ui/icons/Star";
import React, { createRef, FC, RefObject, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import { SecondaryButton } from "../atoms/Button";
import { Checkbox } from "../atoms/Checkbox";
import { InputTypeNumber } from "../atoms/InputTypeNumber";
import { cardNames, FormValues, notices } from "../entrypoints/CreditCard";
import {
  calcTotalUserAmount,
  calcTotalCardAmounts,
  calcAvgCardRates,
  amountFormat,
} from "../libs/Calculator";
import { mediaQueries, breakpoints } from "../styles/mediaqueries";
import { color, elevation, text } from "../styles/theme";
import { TableRate } from "./TableRate";

type Props = {
  scrollCardId: number;
};

export const Table: FC<Props> = ({ scrollCardId }) => {
  const {
    register,
    watch,
    control,
    formState: { isSubmitted, errors },
  } = useFormContext<FormValues>();
  const categories = watch("category");
  const pins = watch("pin");
  const [showBallon, setShowBallon] = useState(true);
  const isPC: boolean = useMediaQuery("(min-width:" + breakpoints.lg + "px)");

  const cardRefs = cardNames.reduce((cardRefs, card) => {
    cardRefs[card.cardid] = createRef<HTMLTableCellElement>();
    return cardRefs;
  }, {} as { [key: number]: RefObject<HTMLTableCellElement> });
  useEffect(() => {
    if (!cardRefs[scrollCardId]) return;
    cardRefs[scrollCardId].current?.scrollIntoView({ behavior: "smooth" });
  }, [cardRefs, scrollCardId]);

  const noticeRefs = notices.reduce((noticeRefs, notice) => {
    noticeRefs[notice.id] = createRef<HTMLDivElement>();
    return noticeRefs;
  }, {} as { [key: number]: RefObject<HTMLDivElement> });
  const scrollToNotice = (noticeId: number) => {
    noticeRefs[noticeId].current?.scrollIntoView({ behavior: "smooth" });
  };

  const totalUserAmount = calcTotalUserAmount(categories);
  const totalCardAmounts = calcTotalCardAmounts(categories);
  const avgCardRates = calcAvgCardRates(categories);

  const pinCount = pins
    ? pins.reduce((c, p) => {
        return p.checked ? c + 1 : c;
      }, 0)
    : 0;

  const cardOrders = cardNames.reduce((o, c, i) => {
    o[c.cardid] = i;
    return o;
  }, [] as number[]);

  const topRateCardId = Number(
    Object.entries(avgCardRates).reduce(
      (t, [i, c]) => {
        return t[1] == 0 || t[1] < c ? [i, c] : t;
      },
      ["0", 0]
    )[0]
  );

  const toAnnualfeeText = (fee: number, type: string): string => {
    if (fee == 0) return "無料";

    let prefix = "";
    if (type == "monthly") {
      prefix = "月会費";
      fee = Math.round(fee / 12);
    }
    return `${prefix}${amountFormat(fee)}円（税込）`;
  };

  return (
    <>
      <div
        css={tableWrapper}
        style={!isPC && !isSubmitted ? { overflow: "hidden" } : undefined}
      >
        <table css={table}>
          <tbody>
            <tr>
              {isPC ? (
                <th css={[tableFixedFront, cellWide]} colSpan={2}>
                  ピン留め
                </th>
              ) : (
                <th css={[tableFixedFront, cellWide]}>ピン留め</th>
              )}
              {cardNames.map((card) => (
                <td css={pin} key={card.cardid}>
                  <Checkbox
                    register={register(`pin.${card.cardid}.checked` as const, {
                      disabled:
                        pinCount >= 2 && pins && !pins[card.cardid]?.checked,
                    })}
                  />
                </td>
              ))}
            </tr>
            <tr css={tableFixedHead}>
              {isPC ? (
                <th css={[tableFixedFront, cellWide]} colSpan={2}>
                  商品
                </th>
              ) : (
                <th css={[tableFixedFront, cellWide]}>商品</th>
              )}
              {cardNames.map((card) => (
                <td key={card.cardid} ref={cardRefs[card.cardid]}>
                  {/*現在無効 : 還元率が一番高いカードだけ出す*/}
                  {false && card.cardid == topRateCardId && (
                    <p css={categoryLabel("highReturnRate")}>高還元率!</p>
                  )}
                  <img
                    alt=""
                    css={cardImg}
                    src={card.image}
                    width={60}
                    height={34}
                  />
                  <p css={cardName}>{card.name}</p>
                  {/*
                  <p css={cardScore}>
                    <StarIcon /> {card.star}
                  </p>
                  */}
                  <a
                    href={card.detailurl}
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >
                    <SecondaryButton css={applicationButton}>
                      詳細を見る
                    </SecondaryButton>
                  </a>

                  <p css={cvbtn}>
                    <a
                      href={card.cvurl}
                      target="_blank"
                      rel="noopener nofollow noreferrer"
                    >
                      <SecondaryButton css={applicationButton}>
                        公式サイト
                      </SecondaryButton>
                    </a>
                  </p>
                  {/*
                  <a
                    css={textLink}
                    href={card.detailurl}
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >
                    詳細を見る <LaunchIcon />
                  </a>
                  */}
                </td>
              ))}
            </tr>
            <tr>
              {isPC ? (
                <th css={[tableFixedFront, cellWide]} colSpan={2}>
                  年会費
                </th>
              ) : (
                <th css={[tableFixedFront, cellWide]}>年会費</th>
              )}
              {cardNames.map((card) => (
                <td css={cardItem} key={card.cardid}>
                  {toAnnualfeeText(card.annualfee, card.annualfeetype)}
                  {card.noticeid_annualfee && (
                    <Link
                      to="#"
                      css={noticeLink}
                      onClick={() => {
                        scrollToNotice(card.noticeid_annualfee);
                      }}
                    >
                      &nbsp;※{card.noticeid_annualfee}
                    </Link>
                  )}
                </td>
              ))}
            </tr>
            <tr>
              {isPC ? (
                <th css={[tableFixedFront, cellWide]} colSpan={2}>
                  ポイント名
                </th>
              ) : (
                <th css={[tableFixedFront, cellWide]}>ポイント名</th>
              )}
              {cardNames.map((card) => (
                <td css={cardItem} key={card.cardid}>
                  {card.pointname}
                </td>
              ))}
            </tr>
            <tr>
              {!isPC && (
                <th
                  css={tableFixed}
                  style={{ whiteSpace: "nowrap", borderRight: 0 }}
                  colSpan={2}
                >
                  ポイント還元内訳(単位：円分)
                </th>
              )}

              {isPC && (
                <th css={tableFixed}>
                  {showBallon && (
                    <div css={balloonWrapper}>
                      <button
                        css={balloon}
                        onClick={() => setShowBallon(false)}
                      >
                        利用金額を入力して、貯まるポイントを比較しよう！{" "}
                        <CloseIcon />
                      </button>
                    </div>
                  )}
                </th>
              )}
              <th
                css={tableFixed}
                style={{ whiteSpace: "nowrap", zIndex: 0, borderLeft: 0 }}
                colSpan={cardNames.length - (isPC ? 0 : 2)}
              ></th>
            </tr>

            <tr>
              <th css={priceFixed}>利用金額合計</th>
              <td css={[priceItemTotal, priceItemFixed]}>
                <strong>{amountFormat(totalUserAmount)}</strong>円/年
              </td>
              <td css={priceItemTotal} colSpan={cardNames.length - 1}></td>
            </tr>

            <tr>
              <th css={priceFixed}>
                <>
                  貯まるポイント
                  <Link
                    to="#"
                    css={noticeLink}
                    onClick={() => {
                      scrollToNotice(1);
                    }}
                  >
                    &nbsp;※1
                  </Link>
                </>
              </th>
              {isPC && <td css={[priceItemTotal, priceItemFixed]}></td>}
              {cardNames.map((card) => (
                <td css={priceItemTotal} key={card.cardid}>
                  <strong>
                    {amountFormat(totalCardAmounts[card.cardid] || 0)}
                  </strong>
                  円分/年
                  {card.noticeid_amount && (
                    <Link
                      to="#"
                      css={noticeLink}
                      onClick={() => {
                        scrollToNotice(card.noticeid_amount);
                      }}
                    >
                      &nbsp;※{card.noticeid_amount}
                    </Link>
                  )}
                </td>
              ))}
            </tr>
            {categories.map((category, index) => (
              <React.Fragment key={index}>
                <tr>
                  <th css={cellShort} style={{ borderRight: 0 }} colSpan={2}>
                    {category.category_name}
                  </th>
                  <th
                    css={cellShort}
                    style={{ zIndex: 0, borderLeft: 0 }}
                    colSpan={cardNames.length - (isPC ? 0 : 2)}
                  ></th>
                </tr>
                {category.stores.map((store, storeindex) => (
                  <tr key={storeindex}>
                    <th css={tableFixed}>{store.name}</th>
                    {isPC && (
                      <td css={[cardInputItem, priceItemFixed]}>
                        <div css={cardInputItemWrapper}>
                          <InputTypeNumber
                            register={register(
                              `category.${index}.stores.${storeindex}.userprice` as const,
                              {
                                valueAsNumber: true,
                                required: true,
                                pattern: /[0-9]*/,
                              }
                            )}
                            placeholder="10,000"
                          />
                          <p css={cardInputUnit}>円/月</p>
                        </div>
                        <span css={errorMessage}>
                          {errors?.category?.[index]?.stores?.[storeindex]
                            ?.userprice
                            ? "半角数字のみ"
                            : ""}
                        </span>
                      </td>
                    )}
                    {store.returnRate
                      .sort((a, b) => {
                        return cardOrders[a.cardid] < cardOrders[b.cardid]
                          ? -1
                          : 1;
                      })
                      .map((card, rateindex) => (
                        <td css={priceItem} key={rateindex}>
                          <TableRate
                            control={control}
                            rate={card.rate}
                            name={
                              `category.${index}.stores.${storeindex}.userprice` as const
                            }
                          />
                        </td>
                      ))}
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      {(isPC || isSubmitted) && (
        <div css={noticeWrap}>
          {notices.map((notice) => (
            <div key={notice.id} ref={noticeRefs[notice.id]}>
              <h3 id={`p${notice.id}`} css={noticeTitle}>
                ※{notice.id}&nbsp;{notice.title}
              </h3>
              {notice.body.split("\n").map((bodyLine, idx) => (
                <p key={idx} css={noticeBody}>
                  {bodyLine.length > 0 ? bodyLine.replace("  ", "　") : "　"}
                </p>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const pin = css`
  display: table-cell;
  text-align: center;
  vertical-align: middle;
`;

const categoryLabel = (value: string) => css`
  background: ${value === "inUse" ? color.slate[100] : color.edesuYellow[200]};
  border-radius: 999px;
  font-size: ${text.xs};
  font-weight: bold;
  padding: 4px 8px;
  text-align: center;
`;

const cardImg = css`
  display: block;
  margin: 8px auto 0;

  ${mediaQueries("lg")} {
    width: 100px;
    height: 58px;
    object-fit: cover;
  } ;
`;

const cardName = css`
  font-weight: bold;
  font-size: ${text.sm};
  margin-top: 8px;
  text-align: center;
`;

const cardScore = css`
  align-items: center;
  display: flex;
  font-size: ${text.xs};
  font-weight: bold;
  justify-content: center;
  margin: 8px auto;
  text-align: center;
  vertical-align: baseline;

  svg {
    color: ${color.edesuYellow[400]};
    height: ${text.sm};
    width: ${text.sm};
  }
`;

const cardItem = css`
  display: table-cell;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
`;

const cardInputItem = css`
  background: ${color.edesuYellow[200]};
  text-align: center;
  width: 155px;

  ${mediaQueries("lg")} {
    width: 200px;
  } ;
`;

const cardInputItemWrapper = css`
  align-items: center;
  display: flex;
  gap: 4px;
  text-align: right;
`;

const cardInputUnit = css`
  width: 3rem;
`;

const applicationButton = css`
  width: 100%;
  margin-top: 10px;
`;

const cvbtn = css`
  margin-top: 4px;
`;

const textLink = css`
  color: ${color.blue[600]};
  display: block;
  font-size: ${text.xs};
  margin-top: 8px;
  text-align: center;
  text-decoration: none;

  svg {
    height: ${text.xs};
    width: ${text.xs};
  }
`;

const priceFixed = css`
  background: ${color.edesuYellow[200]};
  height: 40px;
  left: 0;
  min-height: 40px;
  text-align: right;
  vertical-align: middle;
  z-index: 1;

  ${mediaQueries("lg")} {
    min-height: 64px;
    height: 64px;
  } ;
`;

const tableFixed = css`
  background: ${color.slate[100]};
  height: 40px;
  left: 0;
  min-height: 40px;
  min-width: 80px;
  text-align: left;
  width: 80px;
  vertical-align: top;
  z-index: 1;

  ${mediaQueries("lg")} {
    min-height: 64px;
    min-width: 112px;
    height: 64px;
    width: 112px;
  } ;
`;

const tableFixedHead = css`
  background: ${color.slate[0]};
  position: sticky;
  top: 0;
  z-index: 5;
`;

const tableFixedFront = css`
  background: ${color.slate[100]};
  left: 0;
  position: sticky;
  text-align: left;
  z-index: 4;
`;

const tableWrapper = css`
  margin: 0 16px;
  height: 600px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  ${mediaQueries("lg")} {
    height: 1024px;
    margin: 16px 0;
  } ;
`;

const table = css`
  background: ${color.slate[0]};
  border: 1px solid ${color.divider.onSurface};
  border-collapse: collapse;
  color: ${color.slate[800]};
  font-size: ${text.sm};
  max-height: 100%;
  display: table;

  th {
    border: 1px solid ${color.divider.onSurface};
    border-collapse: collapse;
    display: table-cell;
    font-size: ${text.xs};
    padding: 8px;
    position: sticky;
    vertical-align: middle;
  }

  td {
    border-top: 1px solid ${color.divider.onSurface};
    border-bottom: 1px solid ${color.divider.onSurface};
    border-collapse: collapse;
    font-size: ${text.sm};
    min-width: 140px;
    padding: 8px;
    width: 140px;

    ${mediaQueries("lg")} {
      width: 160px;
      min-width: 160px;
    }
  }
`;

const priceItem = css`
  display: table-cell;
  height: 48px;
  text-align: right;
  vertical-align: middle;

  ${mediaQueries("lg")} {
    height: 64px;
  } ;
`;

const priceItemTotal = css`
  background: ${color.edesuYellow[200]};
  display: table-cell;
  height: 48px;
  text-align: right;
  vertical-align: middle;

  strong {
    font-size: ${text.lg};
  }

  ${mediaQueries("lg")} {
    height: 64px;
  } ;
`;

const priceItemFixed = css`
  position: sticky;
  left: 70px;
`;

const cellWide = css`
  min-height: 40px;
  height: 40px;
  min-width: 80px;
  width: 80px;

  ${mediaQueries("lg")} {
    min-height: 64px;
    height: 64px;
    min-width: 200px;
    width: 200px;
  } ;
`;

const cellShort = css`
  background: ${color.slate[100]};
  display: table-cell;
  height: 32px;
  left: 0;
  min-height: 32px;
  text-align: left;
  position: sticky;
  vertical-align: top;
  z-index: 1;

  ${mediaQueries("lg")} {
    height: 40px;
    min-width: 40px;
  } ;
`;

const balloonWrapper = css`
  position: absolute;
  top: 15px;
  left: 37px;
  z-index: 10;
`;

const balloon = css`
  align-items: center;
  background: ${color.edesuYellow[200]};
  border-radius: 999px;
  box-shadow: ${elevation[8]};
  color: ${color.slate[800]};
  display: flex;
  font-size: ${text.xs};
  justify-content: space-between;
  padding: 4px 16px;
  position: relative;
  width: 360px;

  &:before {
    border: 8px solid transparent;
    border-top: 8px solid ${color.edesuYellow[200]};
    content: "";
    left: 50%;
    margin-left: -15px;
    top: 100%;
    position: absolute;
  }

  svg {
    height: ${text.base};
    width: ${text.base};
  }
`;

const button = css`
  width: 100%;
  margin-top: 16px;
`;

const errorMessage = css`
  color: ${color.red[600]};
  display: block;
  font-size: ${text.sm}px;
`;

const noticeWrap = css`
  margin: 0px 16px;
  ${mediaQueries("lg")} {
    margin: 0;
  }
`;

const noticeLink = css`
  color: ${color.blue[600]};
  font-size: 80%;
`;

const noticeTitle = css`
  margin-top: 0.6rem;
`;

const noticeBody = css`
  font-size: 80%;
`;
