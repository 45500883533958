import acMastercard from "../images/creditcard/ac_mastercard.png";
import aeonSelect from "../images/creditcard/aeon_select.png";
import amazonPrime from "../images/creditcard/amazonprime.png";
import amex from "../images/creditcard/amex.png";
import amexGold from "../images/creditcard/amex_gold.png";
import amexPlatinum from "../images/creditcard/amex_platinum.png";
import auPay from "../images/creditcard/au_pay.png";
import auPayGold from "../images/creditcard/au_pay_gold.png";
import biccameraSuica from "../images/creditcard/biccamera_suica.png";
import dCard from "../images/creditcard/d_card.png";
import dCardGold from "../images/creditcard/d_card_gold.png";
import Dummy from "../images/creditcard/dummy.jpg";
import epos from "../images/creditcard/epos.png";
import jcbGold from "../images/creditcard/jcb_gold.png";
import jcbPlatinum from "../images/creditcard/jcb_platinum.png";
import jcbW from "../images/creditcard/jcb_w.png";
import lawsonPontaPlus from "../images/creditcard/lawson_ponta_plus.png";
import life from "../images/creditcard/life.png";
import linePay from "../images/creditcard/line_pay.png";
import linePayP from "../images/creditcard/line_pay_p.png";
import marriottBonvoy from "../images/creditcard/marriott_bonvoy.png";
import mufgCard from "../images/creditcard/mufg_card.png";
import oricoThepoint from "../images/creditcard/orico_thepoint.png";
import rakutenNormal from "../images/creditcard/rakuten_normal.png";
import rakutenPremium from "../images/creditcard/rakuten_premium.png";
import recruitCard from "../images/creditcard/recruit_card.png";
import saisonPearlAmex from "../images/creditcard/saison_pearl_amex.png";
import sevenCardPlus from "../images/creditcard/seven_card_plus.png";
import smbcGoldNumberless from "../images/creditcard/smbc_gold_numberless.png";
import smbcNumberless from "../images/creditcard/smbc_numberless.png";
import smbcPlatinumPreferred from "../images/creditcard/smbc_platinum_preferred.png";
import tcardPrime from "../images/creditcard/tcard_prime.png";
import viasoNormal from "../images/creditcard/viaso_normal.png";
import viewSuica from "../images/creditcard/view_suica.png";
import yahooNormal from "../images/creditcard/yahoo_normal.png";

export type FormValuesRate = {
  cardid: number;
  rate: number;
};

export type FormValuesStore = {
  storeid: number;
  name: string;
  userprice: number;
  returnRate: FormValuesRate[];
};

export type FormValuesCategory = {
  category_name: string;
  stores: FormValuesStore[];
};

export type FormValuesPin = {
  checked: boolean;
};

export type FormValues = {
  pin: FormValuesPin[];
  category: FormValuesCategory[];
};

export const cardNames = [
  {
    cardid: 1,
    name: "三井住友カード（NL）",
    image: smbcNumberless,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "Vポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_smccnl/",
    cvurl: "https://www.smbc-card.com/nyukai/card/numberless.jsp",
    noticeid_annualfee: null,
    noticeid_amount: 2,
  },
  {
    cardid: 2,
    name: "三井住友カード プラチナプリファード",
    image: smbcPlatinumPreferred,
    star: "3.6（38件）",
    annualfee: 33000,
    annualfeetype: "yearly",
    pointname: "Vポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_smccpp/",
    cvurl: "https://www.smbc-card.com/nyukai/card/platinum-preferred.jsp",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 3,
    name: "三井住友カード ゴールド（NL）",
    image: smbcGoldNumberless,
    star: "3.6（38件）",
    annualfee: 5500,
    annualfeetype: "yearly",
    pointname: "Vポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_smccgnl/",
    cvurl: "https://www.smbc-card.com/nyukai/card/gold-numberless.jsp",
    noticeid_annualfee: null,
    noticeid_amount: 2,
  },
  {
    cardid: 4,
    name: "JCB カード W",
    image: jcbW,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "Oki Dokiポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_jcb_w/",
    cvurl: "https://www.jcb.co.jp/promotion/ordercard/w/only/",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 5,
    name: "JCBゴールド",
    image: jcbGold,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "Oki Dokiポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_jcbgold/",
    cvurl: "https://www.jcb.co.jp/promotion/ordercard/gold/",
    noticeid_annualfee: 6,
    noticeid_amount: null,
  },
  {
    cardid: 6,
    name: "JCBプラチナ",
    image: jcbPlatinum,
    star: "3.6（38件）",
    annualfee: 27500,
    annualfeetype: "yearly",
    pointname: "Oki Dokiポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_jcbplutinum/",
    cvurl: "https://www.jcb.co.jp/promotion/ordercard/platinum/",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 7,
    name: "dカード",
    image: dCard,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "dポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_d/",
    cvurl: "https://dcard.docomo.ne.jp/std/topics/af/regular.html",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 8,
    name: "dカード GOLD",
    image: dCardGold,
    star: "3.6（38件）",
    annualfee: 11000,
    annualfeetype: "yearly",
    pointname: "dポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_dgold/",
    cvurl: "https://dcard.docomo.ne.jp/std/topics/af/gold.html",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 9,
    name: "au PAY カード",
    image: auPay,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "Pontaポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_aupay/",
    cvurl: "https://www.kddi-fs.com/function/promotion/",
    noticeid_annualfee: 5,
    noticeid_amount: null,
  },
  {
    cardid: 10,
    name: "au PAY ゴールドカード",
    image: auPayGold,
    star: "3.6（38件）",
    annualfee: 11000,
    annualfeetype: "yearly",
    pointname: "Pontaポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_aupaygold/",
    cvurl: "https://www.kddi-fs.com/function/promotion/goldlp/",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 11,
    name: "セゾンパール・アメリカン・エキスプレス(R)・カード",
    image: saisonPearlAmex,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "永久不滅ポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_saisonPA/",
    cvurl: "https://www.saisoncard.co.jp/amextop/pearl-pro/",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 12,
    name: "PayPayカード",
    image: yahooNormal,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "PayPayポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_paypay/",
    cvurl: "https://card.yahoo.co.jp/paypaycard/campaign/pre",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 13,
    name: "楽天カード",
    image: rakutenNormal,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "楽天ポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_rakuten/",
    cvurl: "https://www.rakuten-card.co.jp/campaign/rakuten_card/affiliate/b.html",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 14,
    name: "楽天プレミアムカード",
    image: rakutenPremium,
    star: "3.6（38件）",
    annualfee: 11000,
    annualfeetype: "yearly",
    pointname: "楽天ポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_rakutenP/",
    cvurl: "https://www.rakuten-card.co.jp/campaign/premium_card/",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 15,
    name: "リクルートカード",
    image: recruitCard,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "リクルートポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_recruit/",
    cvurl: "https://recruit-card.jp/lp/aff/jcb/",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 16,
    name: "Amazon Prime Mastercard",
    image: amazonPrime,
    star: "3.6（38件）",
    annualfee: 524,
    annualfeetype: "yearly",
    pointname: "Amazonポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_amazonP/",
    cvurl: "https://www.amazon.co.jp/MasterCard_%E3%83%9E%E3%82%B9%E3%82%BF%E3%83%BC%E3%82%AB%E3%83%BC%E3%83%89_/b?ie=UTF8&node=3036192051",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 17,
    name: "Visa LINE Payクレジットカード（P＋）",
    image: linePayP,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "LINEポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_linepayplus/",
    cvurl: "https://www.smbc-card.com/nyukai/affiliate/lpay/index2.jsp",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 18,
    name: "ローソンPontaプラス",
    image: lawsonPontaPlus,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "Pontaポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_lowsonPP/",
    cvurl: "https://www.lawsonbank.jp/lp/lp_credit.html",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 19,
    name: "三菱UFJカード VIASOカード",
    image: viasoNormal,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "VIASOポイントプログラム",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_viasonormal/",
    cvurl: "https://www.cr.mufg.jp/landing/mufgcard/apply/m_viaso/af/index.html",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 20,
    name: "三菱UFJカード",
    image: mufgCard,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "グローバルポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_mufgcard/",
    cvurl: "https://www.cr.mufg.jp/landing/mucard/af_a/index_03.html",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 21,
    name: "エポスカード",
    image: epos,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "エポスポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_epos/",
    cvurl: "https://www.eposcard.co.jp/aflt/index2.html",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 22,
    name: "セブンカード・プラス",
    image: sevenCardPlus,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "nanacoポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_sevenP/",
    cvurl: "https://www.7card.co.jp/lp/plus/aff3/index.html",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 23,
    name: "Tカード Prime",
    image: tcardPrime,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "Tポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_tcardP/",
    cvurl: "https://www.jaccs.co.jp/tcardprime_lp1/",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 24,
    name: "Orico Card THE POINT",
    image: oricoThepoint,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "オリコポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_oricoP/",
    cvurl: "https://www.orico.co.jp/creditcard/list/thepoint/",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 25,
    name: "イオンカードセレクト",
    image: aeonSelect,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "WAON POINT",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_aeonselect/",
    cvurl: "https://www.aeon.co.jp/campaign/lp/aeoncardwaon/",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 26,
    name: "アメリカン・エキスプレス(R)・グリーン・カード",
    image: amex,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "メンバーシップ・リワード",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_amex/",
    cvurl: "https://www.americanexpress.com/jp/credit-cards/green-card/",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 27,
    name: "アメリカン・エキスプレス(R)・ゴールド・カード",
    image: amexGold,
    star: "3.6（38件）",
    annualfee: 0,
    annualfeetype: "yearly",
    pointname: "メンバーシップ・リワード",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_amexGold/",
    cvurl: "https://www.americanexpress.com/jp/credit-cards/gold-card/",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 28,
    name: "アメリカン・エキスプレス(R)・プラチナ・カード",
    image: amexPlatinum,
    star: "3.6（38件）",
    annualfee: 13200,
    annualfeetype: "monthly",
    pointname: "メンバーシップ・リワード",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_amexPlutinum/",
    cvurl: "https://www.americanexpress.com/jp/credit-cards/platinum-card/",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 29,
    name: "Marriott Bonvoyアメリカン・エキスプレス・カード",
    image: marriottBonvoy,
    star: "3.6（38件）",
    annualfee: 31900,
    annualfeetype: "yearly",
    pointname: "Marriott Bonvoyポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_marriot/",
    cvurl: "https://www.americanexpress.com/jp/credit-cards/marriott-bonvoy-base/",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 30,
    name: "ライフカード「年会費無料」",
    image: life,
    star: "3.6（38件）",
    annualfee: 143000,
    annualfeetype: "yearly",
    pointname: "LIFEサンクスポイント",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_life/",
    cvurl: "https://lifecard-promotion-dg.com/nyukai/",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
  {
    cardid: 31,
    name: "ACマスターカード",
    image: acMastercard,
    star: "3.6（38件）",
    annualfee: 23100,
    annualfeetype: "yearly",
    pointname: "キャッシュバック",
    detailurl: "https://www.a-tm.co.jp/top/creditcard/pointsimulater_card_acmaster/",
    cvurl: "https://www.acom.co.jp/p/lp/creditcard/10/",
    noticeid_annualfee: null,
    noticeid_amount: null,
  },
];

export const storeNames: FormValuesCategory[] = [
  {
    category_name: "電子マネー",
    stores: [
      {
        storeid: 1,
        name: "QUIC PAY",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0 },
          { cardid: 2, rate: 0 },
          { cardid: 3, rate: 0 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0 },
          { cardid: 8, rate: 0 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.02 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0 },
          { cardid: 17, rate: 0 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 2,
        name: "iD",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0 },
          { cardid: 5, rate: 0 },
          { cardid: 6, rate: 0 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0 },
          { cardid: 10, rate: 0 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0 },
          { cardid: 13, rate: 0 },
          { cardid: 14, rate: 0 },
          { cardid: 15, rate: 0 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0 },
          { cardid: 20, rate: 0 },
          { cardid: 21, rate: 0 },
          { cardid: 22, rate: 0 },
          { cardid: 23, rate: 0 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0 },
          { cardid: 27, rate: 0 },
          { cardid: 28, rate: 0 },
          { cardid: 29, rate: 0 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0 },
        ],
      },
      {
        storeid: 3,
        name: "au PAY",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0 },
          { cardid: 5, rate: 0 },
          { cardid: 6, rate: 0 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.02 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0 },
          { cardid: 14, rate: 0 },
          { cardid: 15, rate: 0 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 4,
        name: "WAON",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0 },
          { cardid: 2, rate: 0 },
          { cardid: 3, rate: 0 },
          { cardid: 4, rate: 0 },
          { cardid: 5, rate: 0 },
          { cardid: 6, rate: 0 },
          { cardid: 7, rate: 0 },
          { cardid: 8, rate: 0 },
          { cardid: 9, rate: 0 },
          { cardid: 10, rate: 0 },
          { cardid: 11, rate: 0 },
          { cardid: 12, rate: 0 },
          { cardid: 13, rate: 0 },
          { cardid: 14, rate: 0 },
          { cardid: 15, rate: 0 },
          { cardid: 16, rate: 0 },
          { cardid: 17, rate: 0 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0 },
          { cardid: 20, rate: 0 },
          { cardid: 21, rate: 0 },
          { cardid: 22, rate: 0 },
          { cardid: 23, rate: 0 },
          { cardid: 24, rate: 0 },
          { cardid: 25, rate: 0 },
          { cardid: 26, rate: 0 },
          { cardid: 27, rate: 0 },
          { cardid: 28, rate: 0 },
          { cardid: 29, rate: 0 },
          { cardid: 30, rate: 0 },
          { cardid: 31, rate: 0 },
        ],
      },
      {
        storeid: 5,
        name: "nanaco",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0 },
          { cardid: 2, rate: 0 },
          { cardid: 3, rate: 0 },
          { cardid: 4, rate: 0 },
          { cardid: 5, rate: 0 },
          { cardid: 6, rate: 0 },
          { cardid: 7, rate: 0 },
          { cardid: 8, rate: 0 },
          { cardid: 9, rate: 0 },
          { cardid: 10, rate: 0 },
          { cardid: 11, rate: 0 },
          { cardid: 12, rate: 0 },
          { cardid: 13, rate: 0 },
          { cardid: 14, rate: 0 },
          { cardid: 15, rate: 0 },
          { cardid: 16, rate: 0 },
          { cardid: 17, rate: 0 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0 },
          { cardid: 20, rate: 0 },
          { cardid: 21, rate: 0 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0 },
          { cardid: 24, rate: 0 },
          { cardid: 25, rate: 0 },
          { cardid: 26, rate: 0 },
          { cardid: 27, rate: 0 },
          { cardid: 28, rate: 0 },
          { cardid: 29, rate: 0 },
          { cardid: 30, rate: 0 },
          { cardid: 31, rate: 0 },
        ],
      },
      {
        storeid: 6,
        name: "d払い",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0 },
          { cardid: 2, rate: 0 },
          { cardid: 3, rate: 0 },
          { cardid: 4, rate: 0 },
          { cardid: 5, rate: 0 },
          { cardid: 6, rate: 0 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0 },
          { cardid: 10, rate: 0 },
          { cardid: 11, rate: 0 },
          { cardid: 12, rate: 0 },
          { cardid: 13, rate: 0 },
          { cardid: 14, rate: 0 },
          { cardid: 15, rate: 0 },
          { cardid: 16, rate: 0 },
          { cardid: 17, rate: 0 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0 },
          { cardid: 20, rate: 0 },
          { cardid: 21, rate: 0 },
          { cardid: 22, rate: 0 },
          { cardid: 23, rate: 0 },
          { cardid: 24, rate: 0 },
          { cardid: 25, rate: 0 },
          { cardid: 26, rate: 0 },
          { cardid: 27, rate: 0 },
          { cardid: 28, rate: 0 },
          { cardid: 29, rate: 0 },
          { cardid: 30, rate: 0 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 7,
        name: "LINE Pay",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0 },
          { cardid: 5, rate: 0 },
          { cardid: 6, rate: 0 },
          { cardid: 7, rate: 0 },
          { cardid: 8, rate: 0 },
          { cardid: 9, rate: 0 },
          { cardid: 10, rate: 0 },
          { cardid: 11, rate: 0 },
          { cardid: 12, rate: 0 },
          { cardid: 13, rate: 0 },
          { cardid: 14, rate: 0 },
          { cardid: 15, rate: 0 },
          { cardid: 16, rate: 0 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0 },
          { cardid: 20, rate: 0 },
          { cardid: 21, rate: 0 },
          { cardid: 22, rate: 0 },
          { cardid: 23, rate: 0 },
          { cardid: 24, rate: 0 },
          { cardid: 25, rate: 0 },
          { cardid: 26, rate: 0 },
          { cardid: 27, rate: 0 },
          { cardid: 28, rate: 0 },
          { cardid: 29, rate: 0 },
          { cardid: 30, rate: 0 },
          { cardid: 31, rate: 0 },
        ],
      },
      {
        storeid: 8,
        name: "PayPay",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 9,
        name: "楽天ペイ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 10,
        name: "楽天Edy",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0 },
          { cardid: 2, rate: 0 },
          { cardid: 3, rate: 0 },
          { cardid: 4, rate: 0 },
          { cardid: 5, rate: 0 },
          { cardid: 6, rate: 0 },
          { cardid: 7, rate: 0 },
          { cardid: 8, rate: 0 },
          { cardid: 9, rate: 0 },
          { cardid: 10, rate: 0 },
          { cardid: 11, rate: 0 },
          { cardid: 12, rate: 0 },
          { cardid: 13, rate: 0.005 },
          { cardid: 14, rate: 0.005 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0 },
          { cardid: 17, rate: 0 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0 },
          { cardid: 24, rate: 0 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0 },
          { cardid: 27, rate: 0 },
          { cardid: 28, rate: 0 },
          { cardid: 29, rate: 0 },
          { cardid: 30, rate: 0 },
          { cardid: 31, rate: 0 },
        ],
      },
      {
        storeid: 11,
        name: "モバイルSuica",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0 },
          { cardid: 2, rate: 0 },
          { cardid: 3, rate: 0 },
          { cardid: 4, rate: 0 },
          { cardid: 5, rate: 0 },
          { cardid: 6, rate: 0 },
          { cardid: 7, rate: 0 },
          { cardid: 8, rate: 0 },
          { cardid: 9, rate: 0 },
          { cardid: 10, rate: 0 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0 },
          { cardid: 14, rate: 0 },
          { cardid: 15, rate: 0.0075 },
          { cardid: 16, rate: 0 },
          { cardid: 17, rate: 0 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0 },
          { cardid: 20, rate: 0 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.0025 },
          { cardid: 26, rate: 0 },
          { cardid: 27, rate: 0 },
          { cardid: 28, rate: 0 },
          { cardid: 29, rate: 0 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "交通費",
    stores: [
      {
        storeid: 12,
        name: "ETC",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.03 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.01 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 13,
        name: "タクシーアプリGO",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.05 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 14,
        name: "東京無線タクシー",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.02 },
          { cardid: 8, rate: 0.02 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 15,
        name: "JR（えきねっと）",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 16,
        name: "JR（モバイルSuicaで定期券購入）",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 17,
        name: "その他交通費",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "ガソリンスタンド",
    stores: [
      {
        storeid: 18,
        name: "idemitsu",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.015 },
          { cardid: 5, rate: 0.0075 },
          { cardid: 6, rate: 0.0075 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.015 },
          { cardid: 10, rate: 0.015 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 19,
        name: "ENEOS",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.015 },
          { cardid: 8, rate: 0.015 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 20,
        name: "コスモ石油",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.00625 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 21,
        name: "その他ガソリンスタンド",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "モバイル回線",
    stores: [
      {
        storeid: 22,
        name: "Y!mobile",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.01 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 23,
        name: "SoftBank",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.01 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 24,
        name: "au",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.11 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.01 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 25,
        name: "楽天モバイル",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 26,
        name: "docomo",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.1 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.01 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 27,
        name: "その他モバイル回線",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "固定回線",
    stores: [
      {
        storeid: 28,
        name: "SoftBank光",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.01 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 29,
        name: "コミュファ光",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 30,
        name: "docomo光",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.1 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.01 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 31,
        name: "au光",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.11 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.01 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 32,
        name: "楽天ひかり",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 33,
        name: "その他固定回線",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "電力会社",
    stores: [
      {
        storeid: 34,
        name: "ENEOSでんき",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.015 },
          { cardid: 8, rate: 0.015 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.015 },
          { cardid: 14, rate: 0.015 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.01 },
          { cardid: 22, rate: 0.01 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 35,
        name: "東京電力",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.015 },
          { cardid: 14, rate: 0.015 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.0015 },
          { cardid: 27, rate: 0.0015 },
          { cardid: 28, rate: 0.0015 },
          { cardid: 29, rate: 0.0015 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 36,
        name: "中部電力ミライズ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.002 },
          { cardid: 14, rate: 0.002 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.0015 },
          { cardid: 27, rate: 0.0015 },
          { cardid: 28, rate: 0.0015 },
          { cardid: 29, rate: 0.0015 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 37,
        name: "関西電力",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.002 },
          { cardid: 14, rate: 0.002 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.0015 },
          { cardid: 27, rate: 0.0015 },
          { cardid: 28, rate: 0.0015 },
          { cardid: 29, rate: 0.0015 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 38,
        name: "スマ電",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.002 },
          { cardid: 14, rate: 0.002 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 39,
        name: "楽天でんき",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 40,
        name: "docomoでんき",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.1 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.002 },
          { cardid: 14, rate: 0.002 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 41,
        name: "auでんき",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.03 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.002 },
          { cardid: 14, rate: 0.002 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 42,
        name: "その他電力会社",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "ガス会社",
    stores: [
      {
        storeid: 43,
        name: "楽天ガス",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 44,
        name: "auガス",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.03 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.002 },
          { cardid: 14, rate: 0.002 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 45,
        name: "東京ガス",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.002 },
          { cardid: 14, rate: 0.002 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.0015 },
          { cardid: 27, rate: 0.0015 },
          { cardid: 28, rate: 0.0015 },
          { cardid: 29, rate: 0.0015 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 46,
        name: "東邦ガス",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.002 },
          { cardid: 14, rate: 0.002 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.0015 },
          { cardid: 27, rate: 0.0015 },
          { cardid: 28, rate: 0.0015 },
          { cardid: 29, rate: 0.0015 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 47,
        name: "大阪ガス",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.002 },
          { cardid: 14, rate: 0.002 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.0015 },
          { cardid: 27, rate: 0.0015 },
          { cardid: 28, rate: 0.0015 },
          { cardid: 29, rate: 0.0015 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 48,
        name: "関西電力ガス",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.002 },
          { cardid: 14, rate: 0.002 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.0015 },
          { cardid: 27, rate: 0.0015 },
          { cardid: 28, rate: 0.0015 },
          { cardid: 29, rate: 0.0015 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 49,
        name: "その他ガス会社",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "水道",
    stores: [
      {
        storeid: 50,
        name: "東京都水道局",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.002 },
          { cardid: 14, rate: 0.002 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.0015 },
          { cardid: 27, rate: 0.0015 },
          { cardid: 28, rate: 0.0015 },
          { cardid: 29, rate: 0.0015 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 51,
        name: "神奈川県営水道",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.002 },
          { cardid: 14, rate: 0.002 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.0015 },
          { cardid: 27, rate: 0.0015 },
          { cardid: 28, rate: 0.0015 },
          { cardid: 29, rate: 0.0015 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 52,
        name: "横浜市水道局",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.002 },
          { cardid: 14, rate: 0.002 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.0015 },
          { cardid: 27, rate: 0.0015 },
          { cardid: 28, rate: 0.0015 },
          { cardid: 29, rate: 0.0015 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 53,
        name: "名古屋市上下水道局",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.002 },
          { cardid: 14, rate: 0.002 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.0015 },
          { cardid: 27, rate: 0.0015 },
          { cardid: 28, rate: 0.0015 },
          { cardid: 29, rate: 0.0015 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 54,
        name: "大阪市水道局",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.002 },
          { cardid: 14, rate: 0.002 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.0015 },
          { cardid: 27, rate: 0.0015 },
          { cardid: 28, rate: 0.0015 },
          { cardid: 29, rate: 0.0015 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 55,
        name: "京都市上下水道局",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.002 },
          { cardid: 14, rate: 0.002 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.0015 },
          { cardid: 27, rate: 0.0015 },
          { cardid: 28, rate: 0.0015 },
          { cardid: 29, rate: 0.0015 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 56,
        name: "その他水道局",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "証券会社",
    stores: [
      {
        storeid: 57,
        name: "楽天証券",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0 },
          { cardid: 2, rate: 0 },
          { cardid: 3, rate: 0 },
          { cardid: 4, rate: 0 },
          { cardid: 5, rate: 0 },
          { cardid: 6, rate: 0 },
          { cardid: 7, rate: 0 },
          { cardid: 8, rate: 0 },
          { cardid: 9, rate: 0 },
          { cardid: 10, rate: 0 },
          { cardid: 11, rate: 0 },
          { cardid: 12, rate: 0 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0 },
          { cardid: 16, rate: 0 },
          { cardid: 17, rate: 0 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0 },
          { cardid: 20, rate: 0 },
          { cardid: 21, rate: 0 },
          { cardid: 22, rate: 0 },
          { cardid: 23, rate: 0 },
          { cardid: 24, rate: 0 },
          { cardid: 25, rate: 0 },
          { cardid: 26, rate: 0 },
          { cardid: 27, rate: 0 },
          { cardid: 28, rate: 0 },
          { cardid: 29, rate: 0 },
          { cardid: 30, rate: 0 },
          { cardid: 31, rate: 0 },
        ],
      },
      {
        storeid: 58,
        name: "SBI証券",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.01 },
          { cardid: 4, rate: 0 },
          { cardid: 5, rate: 0 },
          { cardid: 6, rate: 0 },
          { cardid: 7, rate: 0 },
          { cardid: 8, rate: 0 },
          { cardid: 9, rate: 0 },
          { cardid: 10, rate: 0 },
          { cardid: 11, rate: 0 },
          { cardid: 12, rate: 0 },
          { cardid: 13, rate: 0 },
          { cardid: 14, rate: 0 },
          { cardid: 15, rate: 0 },
          { cardid: 16, rate: 0 },
          { cardid: 17, rate: 0 },
          { cardid: 18, rate: 0 },
          { cardid: 19, rate: 0 },
          { cardid: 20, rate: 0 },
          { cardid: 21, rate: 0 },
          { cardid: 22, rate: 0 },
          { cardid: 23, rate: 0 },
          { cardid: 24, rate: 0 },
          { cardid: 25, rate: 0 },
          { cardid: 26, rate: 0 },
          { cardid: 27, rate: 0 },
          { cardid: 28, rate: 0 },
          { cardid: 29, rate: 0 },
          { cardid: 30, rate: 0 },
          { cardid: 31, rate: 0 },
        ],
      },
      {
        storeid: 59,
        name: "その他証券会社",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "医療費",
    stores: [
      {
        storeid: 60,
        name: "医療費のクレカ支払額額",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "保険",
    stores: [
      {
        storeid: 61,
        name: "国民年金保険料",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0 },
          { cardid: 2, rate: 0 },
          { cardid: 3, rate: 0 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0 },
          { cardid: 8, rate: 0 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.002 },
          { cardid: 14, rate: 0.002 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0 },
          { cardid: 17, rate: 0 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.0015 },
          { cardid: 27, rate: 0.0015 },
          { cardid: 28, rate: 0.0015 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 62,
        name: "その他保険",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "EC",
    stores: [
      {
        storeid: 63,
        name: "楽天市場",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.0075 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.015 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.03 },
          { cardid: 14, rate: 0.05 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 64,
        name: "Amazon",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.02 },
          { cardid: 5, rate: 0.01 },
          { cardid: 6, rate: 0.01 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.015 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.02 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 65,
        name: "yahooショッピング",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.00625 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.015 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.03 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 66,
        name: "その他ECサイト",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "コンビニ",
    stores: [
      {
        storeid: 67,
        name: "セブン-イレブン",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.05 },
          { cardid: 2, rate: 0.05 },
          { cardid: 3, rate: 0.05 },
          { cardid: 4, rate: 0.02 },
          { cardid: 5, rate: 0.01 },
          { cardid: 6, rate: 0.01 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.015 },
          { cardid: 10, rate: 0.015 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.015 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.044 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.01 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 68,
        name: "ローソン",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.05 },
          { cardid: 2, rate: 0.05 },
          { cardid: 3, rate: 0.05 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.015 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.015 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.06 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.044 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 69,
        name: "ファミリーマート",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.05 },
          { cardid: 2, rate: 0.05 },
          { cardid: 3, rate: 0.05 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.015 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.044 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 70,
        name: "ミニストップ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.02 },
          { cardid: 5, rate: 0.01 },
          { cardid: 6, rate: 0.01 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 71,
        name: "デイリーヤマザキ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.01 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.01 },
          { cardid: 4, rate: 0.02 },
          { cardid: 5, rate: 0.01 },
          { cardid: 6, rate: 0.01 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 72,
        name: "セイコーマート",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.05 },
          { cardid: 2, rate: 0.05 },
          { cardid: 3, rate: 0.05 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 73,
        name: "ポプラ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.05 },
          { cardid: 2, rate: 0.05 },
          { cardid: 3, rate: 0.05 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 74,
        name: "その他コンビニ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "スーパー",
    stores: [
      {
        storeid: 75,
        name: "イオン・ダイエー・マックスバリュー",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.01 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 76,
        name: "アピタ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.02 },
          { cardid: 5, rate: 0.01 },
          { cardid: 6, rate: 0.01 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 83,
        name: "平和堂",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.015 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.015 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 84,
        name: "ゆめタウン",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.01 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.01 },
          { cardid: 4, rate: 0.02 },
          { cardid: 5, rate: 0.01 },
          { cardid: 6, rate: 0.01 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 85,
        name: "万代",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.01 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.01 },
          { cardid: 4, rate: 0.02 },
          { cardid: 5, rate: 0.01 },
          { cardid: 6, rate: 0.01 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 86,
        name: "成城石井",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.03 },
          { cardid: 5, rate: 0.015 },
          { cardid: 6, rate: 0.015 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 90,
        name: "ハローズ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.01 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.01 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 91,
        name: "オークワ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.01 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.01 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 92,
        name: "フジ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.01 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.01 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 93,
        name: "フレスコ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.01 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.01 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 94,
        name: "カネスエ・フェルナ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.01 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.01 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 95,
        name: "イトーヨーカドー",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.015 },
          { cardid: 10, rate: 0.015 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.01 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 96,
        name: "西友",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 97,
        name: "その他スーパー",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "ドラッグストア",
    stores: [
      {
        storeid: 98,
        name: "マツモトキヨシ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.01 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.01 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.03 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 99,
        name: "ウエルシア",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.03 },
          { cardid: 5, rate: 0.015 },
          { cardid: 6, rate: 0.015 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 100,
        name: "ココカラファイン",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.01 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.01 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.02 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 101,
        name: "カワチ薬品",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.02 },
          { cardid: 5, rate: 0.01 },
          { cardid: 6, rate: 0.01 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.015 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 102,
        name: "キリン堂",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.02 },
          { cardid: 5, rate: 0.01 },
          { cardid: 6, rate: 0.01 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.015 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 103,
        name: "サツドラ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.02 },
          { cardid: 5, rate: 0.01 },
          { cardid: 6, rate: 0.01 },
          { cardid: 7, rate: 0.015 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.015 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 104,
        name: "ドラッグストアモリ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.02 },
          { cardid: 5, rate: 0.01 },
          { cardid: 6, rate: 0.01 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.015 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 105,
        name: "クスリのアオキ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.01 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.01 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.03 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 106,
        name: "クリエイトＳＤ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.01 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.01 },
          { cardid: 4, rate: 0.02 },
          { cardid: 5, rate: 0.01 },
          { cardid: 6, rate: 0.01 },
          { cardid: 7, rate: 0.03 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 107,
        name: "薬王堂",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.02 },
          { cardid: 5, rate: 0.01 },
          { cardid: 6, rate: 0.01 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.015 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 108,
        name: "ダックス",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.02 },
          { cardid: 5, rate: 0.01 },
          { cardid: 6, rate: 0.01 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.015 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 109,
        name: "ヤックスドラッグ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.01 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.01 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.03 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 110,
        name: "大賀薬局",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.01 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.01 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.03 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 111,
        name: "その他ドラッグストア",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.02 },
          { cardid: 10, rate: 0.02 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "家電量販店",
    stores: [
      {
        storeid: 112,
        name: "エディオン",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.015 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 113,
        name: "コジマ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.015 },
          { cardid: 5, rate: 0.01 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.015 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 114,
        name: "ビックカメラ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.01 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.01 },
          { cardid: 4, rate: 0.015 },
          { cardid: 5, rate: 0.01 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.015 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.01 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 115,
        name: "ジョーシン",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.03 },
          { cardid: 5, rate: 0.02 },
          { cardid: 6, rate: 0.02 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 116,
        name: "ノジマ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.04 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 117,
        name: "その他家電量販店",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "外食（居酒屋含む）",
    stores: [
      {
        storeid: 118,
        name: "マクドナルド",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.05 },
          { cardid: 2, rate: 0.05 },
          { cardid: 3, rate: 0.05 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.015 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 119,
        name: "すき家",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.05 },
          { cardid: 2, rate: 0.05 },
          { cardid: 3, rate: 0.05 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.015 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.015 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 120,
        name: "ミスタードーナッツ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 121,
        name: "かっぱ寿司",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.05 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.02 },
          { cardid: 10, rate: 0.02 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 122,
        name: "はま寿司",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.05 },
          { cardid: 2, rate: 0.05 },
          { cardid: 3, rate: 0.05 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 123,
        name: "ドミノ・ピザ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.015 },
          { cardid: 5, rate: 0.01 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.02 },
          { cardid: 10, rate: 0.02 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 124,
        name: "デニーズ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.01 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 125,
        name: "サイゼリヤ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.05 },
          { cardid: 2, rate: 0.05 },
          { cardid: 3, rate: 0.05 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 126,
        name: "ココス",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.05 },
          { cardid: 2, rate: 0.05 },
          { cardid: 3, rate: 0.05 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 127,
        name: "和民",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.02 },
          { cardid: 5, rate: 0.015 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 128,
        name: "その他飲食店（居酒屋含む）",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "フードデリバリー",
    stores: [
      {
        storeid: 129,
        name: "出前館",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 130,
        name: "その他フードデリバリーサービス",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "カフェ",
    stores: [
      {
        storeid: 131,
        name: "スターバックス",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.055 },
          { cardid: 5, rate: 0.0275 },
          { cardid: 6, rate: 0.0275 },
          { cardid: 7, rate: 0.04 },
          { cardid: 8, rate: 0.04 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 132,
        name: "ドトール",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.05 },
          { cardid: 2, rate: 0.05 },
          { cardid: 3, rate: 0.05 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.04 },
          { cardid: 8, rate: 0.045 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 133,
        name: "エクセルシオール",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.05 },
          { cardid: 2, rate: 0.05 },
          { cardid: 3, rate: 0.05 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 134,
        name: "その他カフェ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "衣類・美容",
    stores: [
      {
        storeid: 135,
        name: "衣類のクレカ支払額",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 136,
        name: "美容院のクレカ支払額",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 137,
        name: "化粧品のクレカ支払額",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 138,
        name: "その他ファッション・美容のクレカ支払額",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "フィットネス・ジム",
    stores: [
      {
        storeid: 139,
        name: "ジムでのクレカ支払額",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "百貨店",
    stores: [
      {
        storeid: 140,
        name: "大丸",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.015 },
          { cardid: 2, rate: 0.03 },
          { cardid: 3, rate: 0.015 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 141,
        name: "三越",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 142,
        name: "伊勢丹",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 143,
        name: "高島屋",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.015 },
          { cardid: 2, rate: 0.03 },
          { cardid: 3, rate: 0.015 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.025 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 144,
        name: "阪急百貨店",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.03 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 145,
        name: "阪神百貨店",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.03 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 146,
        name: "小田急百貨店",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 147,
        name: "西武百貨店",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 148,
        name: "松坂屋",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.015 },
          { cardid: 2, rate: 0.03 },
          { cardid: 3, rate: 0.015 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 149,
        name: "マルイ",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.015 },
          { cardid: 10, rate: 0.015 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 150,
        name: "その他百貨店",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "旅行",
    stores: [
      {
        storeid: 151,
        name: "近畿日本ツーリスト",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 152,
        name: "JTB",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.03 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 153,
        name: "一休.com",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.1 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.015 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 154,
        name: "expedia",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.15 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 155,
        name: "hotels.com",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.15 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 156,
        name: "じゃらん",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 157,
        name: "ホテル・旅館でのクレカ支払額",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 158,
        name: "その他旅行でのクレカ支払額",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "航空会社",
    stores: [
      {
        storeid: 160,
        name: "JAL",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.02 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 161,
        name: "ANA",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 162,
        name: "その他航空会社",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "趣味・娯楽",
    stores: [
      {
        storeid: 163,
        name: "Netflix",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.1 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 164,
        name: "DAZN",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.1 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 165,
        name: "ディズニー＋",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 166,
        name: "その他動画配信サービス",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.03 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.02 },
          { cardid: 10, rate: 0.02 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.025 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 167,
        name: "BIGECHO（カラオケ）",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 168,
        name: "映画館でのクレカ支払額",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 169,
        name: "イベントチケット代全般",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 170,
        name: "その他趣味・娯楽のクレカ支払額",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.03 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "教養・学習",
    stores: [
      {
        storeid: 170,
        name: "蔦屋書店",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.03 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 171,
        name: "紀伊国屋書店",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.01 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.01 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.02 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.02 },
          { cardid: 10, rate: 0.02 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 172,
        name: "その他本屋",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
      {
        storeid: 173,
        name: "その他教養・学習でのクレカ支払額",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },
  {
    category_name: "その他",
    stores: [
      {
        storeid: 174,
        name: "その他クレカ支払額",
        userprice: 0,
        returnRate: [
          { cardid: 1, rate: 0.005 },
          { cardid: 2, rate: 0.01 },
          { cardid: 3, rate: 0.005 },
          { cardid: 4, rate: 0.01 },
          { cardid: 5, rate: 0.005 },
          { cardid: 6, rate: 0.005 },
          { cardid: 7, rate: 0.01 },
          { cardid: 8, rate: 0.01 },
          { cardid: 9, rate: 0.01 },
          { cardid: 10, rate: 0.01 },
          { cardid: 11, rate: 0.005 },
          { cardid: 12, rate: 0.01 },
          { cardid: 13, rate: 0.01 },
          { cardid: 14, rate: 0.01 },
          { cardid: 15, rate: 0.012 },
          { cardid: 16, rate: 0.01 },
          { cardid: 17, rate: 0.01 },
          { cardid: 18, rate: 0.01 },
          { cardid: 19, rate: 0.005 },
          { cardid: 20, rate: 0.004 },
          { cardid: 21, rate: 0.005 },
          { cardid: 22, rate: 0.005 },
          { cardid: 23, rate: 0.01 },
          { cardid: 24, rate: 0.01 },
          { cardid: 25, rate: 0.005 },
          { cardid: 26, rate: 0.003 },
          { cardid: 27, rate: 0.003 },
          { cardid: 28, rate: 0.003 },
          { cardid: 29, rate: 0.006 },
          { cardid: 30, rate: 0.005 },
          { cardid: 31, rate: 0.0025 },
        ],
      },
    ],
  },      
];

export const initFormValues = {
  category: storeNames,
};

export const notices = [
  {
    id: 1,
    title: "ポイント計算方法についての注釈",
    body: `◆ポイントの計算方法に関して、以下の点は考慮されていませんので、ご注意ください。
  1．各クレジットカード会社が運営するポイントモールを利用した場合に還元されるポイント
  2．電子マネーまたはコード決済自体の利用によって還元されるポイント

◆各ポイントは、次の通り「円相当」に換算しております。
  1ポイント=5円換算（Oki Dokiポイント（注）、永久不滅ポイント）
  1ポイント=4円換算（グローバルポイント）
  3ポイント=1円換算（メンバーシップ・リワード）
  1ポイント=1円換算（その他のすべてのポイント）

  注：Oki Dokiポイントは、nanaco、dポイント等に交換した際のレートで計算しております。

◆2022年12月1日時点で、各クレジットカード会社が公式サイトにて公表している情報を元に計算しております。

◆各クレジットカード会社が期間限定で実施しているキャンペーンについては考慮されていませんので、ご注意ください。

◆au PAYに関して、三井住友カード（NL）、三井住友カード ゴールド（NL）、三井住友カード プラチナプリファード、dカード、dカード GOLD、PayPayカード、ライフカード、Orico Card THE POINTは、国際ブランドがMastercard以外の場合はそもそもau PAYにチャージができませんので、ご注意ください。`,
  },
  {
    id: 2,
    title: "",
    body: `三井住友カードが実施している「選んだお店でポイント+0.5%還元」サービス（https://shopselect.smbc-card.com/）に関して、当該サービスの対象店舗はすべて元のポイント還元率に0.5%加えたポイント還元率で計算しております。`,
  },
  {
    id: 3,
    title: "",
    body: `ライフカードはお誕生日月に基本ポイントが3倍になりますが、そちらは考慮されていませんので、ご注意ください。`,
  },
  {
    id: 4,
    title: "",
    body: `ACマスターカードは、ポイントが還元されるわけではなく、キャッシュバックされる金額を計算しております。`,
  },
  {
    id: 5,
    title: "",
    body: `１年間ご利用がない場合は、1,375円（税込）かかります。`,
  },
  {
    id: 6,
    title: "",
    body: `オンライン入会申込限定となります。よって、資料請求での申込、お切り替えの方は対象外となりますので、ご注意ください。`,
  },
];
