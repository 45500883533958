import { css } from "@emotion/react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FC } from "react";
import { FooterAccordion } from "../components/FooterAccordion";
import bnrRegisterMobile from "../images/common/bnr-register-mobile.png";
import bnrRegisterPc from "../images/common/bnr-register-pc.png";
import imgBgTownMobile from "../images/common/img-bg-town-mobile.png";
import imgBgTownPc from "../images/common/img-bg-town-pc.png";
import imgJpx from "../images/common/img-jpx.png";
import { mediaQueries, breakpoints } from "../styles/mediaqueries";
import { color, text } from "../styles/theme";

export const Footer: FC = () => {
  const matches: boolean = useMediaQuery(
    "(min-width:" + breakpoints.lg + "px)"
  );

  return (
    <>
      <footer css={footer}>
        <div css={footerWrapper}>
          {/* TODO: ページ作成後リンク設定 */}
          <nav css={footerNav}>
            <a href="https://www.a-tm.co.jp/top/corporate/" css={footerNavItem}>
              運営者情報
            </a>
            <a href="https://www.a-tm.co.jp/top/expert/" css={footerNavItem}>
              監修者一覧
            </a>
            <a
              href="https://www.a-tm.co.jp/top/contents_policy/"
              css={footerNavItem}
            >
              コンテンツポリシー
            </a>
            <a
              href="https://life-design.a-tm.co.jp/privacy/"
              target="_blank"
              rel="noopener nofollow noreferrer"
              css={footerNavItem}
            >
              プライバシーポリシー
            </a>
            <a
              href="https://www.a-tm.co.jp/securitypolicy/"
              target="_blank"
              rel="noopener nofollow noreferrer"
              css={footerNavItem}
            >
              セキュリティーポリシー
            </a>
            <a
              href="https://www.a-tm.co.jp/top/user_policy/"
              css={footerNavItem}
            >
              利用規約
            </a>
            <a
              href="https://www.a-tm.co.jp/top/usage_environment/"
              css={footerNavItem}
            >
              利用環境
            </a>
            <a
              href="https://www.a-tm.co.jp/contact/form/?type=5"
              target="_blank"
              rel="noopener nofollow noreferrer"
              css={footerNavItem}
            >
              お問い合わせ
            </a>
          </nav>
          <div css={acoordionWrapper}>
            <FooterAccordion
              title="転職"
              link="https://www.a-tm.co.jp/top/job-change/"
            >
              <a
                href="https://www.a-tm.co.jp/top/job-change/tenshoku-agent/"
                css={acoordionItem}
              >
                - おすすめ転職エージェント
              </a>
              <a
                href="https://www.a-tm.co.jp/top/job-change/tenshoku-agent/best-job-change-site/"
                css={acoordionItem}
              >
                - おすすめ転職サイト
              </a>
              <a
                href="https://www.a-tm.co.jp/top/job-change/osusume-agent-woman/"
                css={acoordionItem}
              >
                - 女性向け転職エージェント
              </a>
              <a
                href="https://www.a-tm.co.jp/top/job-change/best-careersite-woman/"
                css={acoordionItem}
              >
                - 女性向け転職サイト
              </a>
              <a
                href="https://www.a-tm.co.jp/top/job-change/best-agent-20th/"
                css={acoordionItem}
              >
                - 20代向け転職エージェント
              </a>
              <a
                href="https://www.a-tm.co.jp/top/job-change/best-agent-30th/"
                css={acoordionItem}
              >
                - 30代向け転職エージェント
              </a>
              <a
                href="https://www.a-tm.co.jp/top/job-change/best-agent-40th/"
                css={acoordionItem}
              >
                - 40代向け転職エージェント
              </a>
              <a
                href="https://www.a-tm.co.jp/top/job-change/tenshoku-agent/career-change-it/"
                css={acoordionItem}
              >
                - IT・エンジニア向け転職エージェント
              </a>
              <a
                href="https://www.a-tm.co.jp/top/job-change/kango-job-change/"
                css={acoordionItem}
              >
                - 看護師向け転職サイト
              </a>
              <a
                href="https://www.a-tm.co.jp/top/job-change/best-site-pharmacist/"
                css={acoordionItem}
              >
                - 薬剤師向け転職サイト
              </a>
            </FooterAccordion>
            <FooterAccordion
              title="クレジットカード"
              link="https://www.a-tm.co.jp/top/creditcard/"
            >
              <a
                href="https://www.a-tm.co.jp/top/creditcard/creditcard-osusume/"
                css={acoordionItem}
              >
                - おすすめクレジットカード
              </a>
              <a
                href="https://www.a-tm.co.jp/top/creditcard/creditcard-osusume/goldcard/"
                css={acoordionItem}
              >
                - ゴールドカード
              </a>
              <a
                href="https://www.a-tm.co.jp/top/creditcard/etccard/"
                css={acoordionItem}
              >
                - ETCカード
              </a>
              <a
                href="https://www.a-tm.co.jp/top/creditcard/creditcard-corporate-card/"
                css={acoordionItem}
              >
                - 法人カード
              </a>
              <a
                href="https://www.a-tm.co.jp/top/creditcard/creditcard-osusume/creditcard-koukangen/"
                css={acoordionItem}
              >
                - 高還元率のクレジットカード
              </a>
              <a
                href="https://www.a-tm.co.jp/top/creditcard/creditcard-sokujitsu/"
                css={acoordionItem}
              >
                - 即日発行OKのクレジットカード
              </a>
              <a
                href="https://www.a-tm.co.jp/top/creditcard/creditcard-osusume/student-creditcard/"
                css={acoordionItem}
              >
                - 学生向けクレジットカード
              </a>
              <a
                href="https://www.a-tm.co.jp/top/creditcard/creditcard-corporate-card/sole-proprietors-creditcard/"
                css={acoordionItem}
              >
                - 個人事業主向けクレジットカード
              </a>
            </FooterAccordion>
            <FooterAccordion
              title="カードローン"
              link="https://www.a-tm.co.jp/top/cardloan/"
            >
              <a
                href="https://www.a-tm.co.jp/top/cardloan/consumer-finance/"
                css={acoordionItem}
              >
                - 消費者金融カードローン
              </a>
              <a
                href="https://www.a-tm.co.jp/top/cardloan/same-day-loan/"
                css={acoordionItem}
              >
                - 即日融資
              </a>
              <a
                href="https://www.a-tm.co.jp/top/cardloan/refinancing/"
                css={acoordionItem}
              >
                - カードローンの借り換え
              </a>
              <a
                href="https://www.a-tm.co.jp/top/cardloan/borrow-money/"
                css={acoordionItem}
              >
                - お金借りる方法
              </a>
              <a
                href="https://www.a-tm.co.jp/top/cardloan/chara-web-only/"
                css={acoordionItem}
              >
                - WEB完結
              </a>
              <a
                href="https://www.a-tm.co.jp/top/cardloan/cardloan-screening/enrollment-confirmation/"
                css={acoordionItem}
              >
                - 在籍確認なし
              </a>
              <a
                href="https://www.a-tm.co.jp/top/cardloan/chara-bank/"
                css={acoordionItem}
              >
                - 銀行カードローン
              </a>
              <a
                href="https://www.a-tm.co.jp/top/cardloan/mycarloan-low-interest/"
                css={acoordionItem}
              >
                - マイカーローン
              </a>
            </FooterAccordion>
            <FooterAccordion
              title="住宅ローン"
              link="https://www.a-tm.co.jp/top/housingloan/"
            >
              <a
                href="https://www.a-tm.co.jp/top/housingloan/housingloan-osusume/"
                css={acoordionItem}
              >
                - おすすめ住宅ローン比較
              </a>
              <a
                href="https://www.a-tm.co.jp/top/housingloan/refinancing/"
                css={acoordionItem}
              >
                - 住宅ローン借り換え
              </a>
              <a
                href="https://www.a-tm.co.jp/top/housingloan/examination/"
                css={acoordionItem}
              >
                - 住宅ローン審査
              </a>
              <a
                href="https://www.a-tm.co.jp/top/housingloan/basicknowledges/"
                css={acoordionItem}
              >
                - 住宅ローンの基礎知識
              </a>
              <a
                href="https://www.a-tm.co.jp/top/housingloan/deduction/"
                css={acoordionItem}
              >
                - 住宅ローン控除・減税
              </a>
              <a
                href="https://www.a-tm.co.jp/top/housingloan/house/"
                css={acoordionItem}
              >
                - 戸建て・注文住宅の知識
              </a>
              <a
                href="https://www.a-tm.co.jp/top/housingloan/mansion/"
                css={acoordionItem}
              >
                - マンション購入の基礎知識
              </a>
              <a
                href="https://www.a-tm.co.jp/top/housingloan/news/"
                css={acoordionItem}
              >
                - 住宅ローンニュース
              </a>
            </FooterAccordion>
            <FooterAccordion
              title="証券投資"
              link="https://www.a-tm.co.jp/top/securities/"
            >
              <a
                href="https://www.a-tm.co.jp/top/securities/investment-trusts-simulation/"
                css={acoordionItem}
              >
                - 積立シミュレーション
              </a>
              <a
                href="https://www.a-tm.co.jp/top/securities/securities-osusume/"
                css={acoordionItem}
              >
                - 証券会社の人気比較ランキング
              </a>
              <a
                href="https://www.a-tm.co.jp/top/securities/company-campaign/"
                css={acoordionItem}
              >
                - 証券会社のキャンペーン
              </a>
              <a
                href="https://www.a-tm.co.jp/top/securities/company-fee/"
                css={acoordionItem}
              >
                - 証券会社の手数料
              </a>
              <a
                href="https://www.a-tm.co.jp/top/securities/account-opening/"
                css={acoordionItem}
              >
                - 株の口座開設
              </a>
              <a
                href="https://www.a-tm.co.jp/top/securities/stock-buy/"
                css={acoordionItem}
              >
                - 株の買い方・やり方・始め方
              </a>
              <a
                href="https://www.a-tm.co.jp/top/securities/america/"
                css={acoordionItem}
              >
                - 外国株(アメリカ株)の買い方
              </a>
              <a
                href="https://www.a-tm.co.jp/top/securities/nisa/"
                css={acoordionItem}
              >
                - NISA,つみたてNISA
              </a>
              <a
                href="https://www.a-tm.co.jp/top/securities/investment-trusts/"
                css={acoordionItem}
              >
                - 投資信託の人気比較
              </a>
              <a
                href="https://www.a-tm.co.jp/top/securities/robo-advisor/"
                css={acoordionItem}
              >
                - ロボアドバイザーの人気比較
              </a>
            </FooterAccordion>
            <FooterAccordion
              title="引越し"
              link="https://www.a-tm.co.jp/top/hikkoshi/"
            >
              <a
                href="https://www.a-tm.co.jp/top/hikkoshi/cost-down/"
                css={acoordionItem}
              >
                - 引越しを安く済ませる方法
              </a>
            </FooterAccordion>
            <FooterAccordion
              title="自動車"
              link="https://www.a-tm.co.jp/top/car/"
            >
              <a
                href="https://www.a-tm.co.jp/top/car/car-selling/"
                css={acoordionItem}
              >
                - 車売却
              </a>
              <a
                href="https://www.a-tm.co.jp/top/car/car-selling/collective-assessment-ranking/"
                css={acoordionItem}
              >
                - おすすめ車一括査定サイト
              </a>
              <a
                href="https://www.a-tm.co.jp/top/car/car-selling/usedcar-assessment-ranking/"
                css={acoordionItem}
              >
                - おすすめ車買取業者
              </a>
              <a
                href="https://www.a-tm.co.jp/top/car/scrap-car/"
                css={acoordionItem}
              >
                - 廃車の方法
              </a>
              <a
                href="https://www.a-tm.co.jp/top/car/scrap-car/scrapped-car-purchaser/"
                css={acoordionItem}
              >
                - おすすめ廃車買取業者
              </a>
              <a
                href="https://www.a-tm.co.jp/top/car/replacement/"
                css={acoordionItem}
              >
                - 車の買い替え
              </a>
            </FooterAccordion>
            <FooterAccordion
              title="調査リリース"
              link="https://www.a-tm.co.jp/top/survey/"
            >
              <></>
            </FooterAccordion>
          </div>
        </div>
      </footer>
      <div css={primeBlock}>
        <div css={primeList}>
          <span css={primeItem}>
            <img
              src={imgJpx}
              alt="東証プライム市場"
              loading="lazy"
              width={48}
              height={56}
            />
          </span>
          <p css={primeBox}>
            「イーデス」は、株式会社エイチームライフデザインが運営するサービスです。
            <br />
            株式会社エイチームライフデザインは、
            <a
              css={primeLink}
              href="https://www.a-tm.co.jp/"
              target="_blank"
              rel="noopener nofollow noreferrer"
            >
              株式会社エイチーム
            </a>
            (東証プライム市場上場)のグループ企業です。
            <br />
            証券コード：3662
          </p>
        </div>
        <p css={copyright}>© 2022 Ateam LifeDesign Inc.</p>
      </div>
    </>
  );
};

const footer = css`
  background: ${color.edesuYellow[200]};
  padding: 4px 12px;
  width: 100%;

  ${mediaQueries("lg")} {
    padding: 0;
  } ;
`;

const footerWrapper = css`
  max-width: 1024px;
  margin: 48px auto;
`;

const footerNav = css`
  display: flex;
  flex-wrap: wrap;
  gap: 20px 0;
  margin-bottom: 32px;

  ${mediaQueries("lg")} {
    width: auto;
  } ;
`;

const footerNavItem = css`
  font-size: ${text.xs};
  flex: 1 1 auto;
  width: calc((100% / 2) - 16px);

  ${mediaQueries("lg")} {
    width: auto;
  } ;
`;

const acoordionWrapper = css`
  display: grid;
  margin-bottom: 8px;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  ${mediaQueries("lg")} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 32px;
  } ;
`;

const acoordionItem = css`
  font-size: ${text.xs};
`;

const primeBlock = css`
  margin: auto;
  padding: 32px 20px;

  ${mediaQueries("lg")} {
    display: flex;
    justify-content: space-between;
    min-width: 1024px;
    padding: 32px 0;
  } ;
`;

const primeList = css`
  display: flex;
  margin-bottom: 8px;

  ${mediaQueries("lg")} {
    margin-bottom: 0;
  } ;
`;

const primeItem = css`
  height: 56px;
  width: 48px;
`;

const primeBox = css`
  flex: 1 1 0%;
  font-size: ${text.xs};
  line-height: 1.5;
  margin-left: 8px;
`;

const primeLink = css`
  color: ${color.slate[800]};
  text-decoration: underline;

  :hover {
    text-decoration: none;
  }
`;

const copyright = css`
  font-size: ${text.xs};
  text-align: center;

  ${mediaQueries("lg")} {
    text-align: right;
  } ;
`;
