import { css } from "@emotion/react";
import { FC, ReactNode } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";

type Props = {
  children: ReactNode;
};

export const Layout: FC<Props> = ({ children, ...props }) => (
  <div css={content}>
    <Header />
    <div css={layout} {...props}>
      {children}
    </div>
    <Footer />
  </div>
);

const content = css`
  display: flex;
  flex-direction: column;
  margin: auto;
  font-size: 16px;
`;

const layout = css`
  flex-grow: 1;
  padding-bottom: 24px;
  width: 100%;
`;
