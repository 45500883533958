import { css } from "@emotion/react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import TableChartIcon from "@material-ui/icons/TableChart";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CardList } from "../components/CardList";
import { SendLinkModal } from "../components/SendLinkModal";
import { Table } from "../components/Table";
import { cardNames, FormValues } from "../entrypoints/CreditCard";
import { calcTotalCardAmounts } from "../libs/Calculator";
import { mediaQueries, breakpoints } from "../styles/mediaqueries";
import { color, text } from "../styles/theme";
import { SimulationModal } from "./SimulationModal";

const SORT_TYPE_TOTAL_AMOUNT = 0;
const SORT_TYPE_ANNUAL_FEE = 1;

export const InformationNav: FC = () => {
  const {
    watch,
    formState: { isSubmitted },
  } = useFormContext<FormValues>();
  const [isTableView, setIsTableView] = useState(false);
  const [scrollCardId, setScrollCardId] = useState(0);
  const [sortType, setSortType] = useState(0);
  const isPC: boolean = useMediaQuery("(min-width:" + breakpoints.lg + "px)");

  const scrollToCard = (cardid: number): void => {
    setIsTableView(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setScrollCardId(cardid);
  };

  const categories = watch("category");
  const pins = watch("pin");
  const totalCardAmounts = calcTotalCardAmounts(categories);

  cardNames.sort((a, b) => {
    let at, bt, sorts;
    switch (sortType) {
      case SORT_TYPE_ANNUAL_FEE:
        at = a.annualfee;
        bt = b.annualfee;
        sorts = [1, -1];
        break;
      case SORT_TYPE_TOTAL_AMOUNT:
      default:
        at = totalCardAmounts[a.cardid] || 0;
        bt = totalCardAmounts[b.cardid] || 0;
        sorts = [-1, 1];
    }
    const ap = pins && pins[a.cardid].checked ? 1 : -1;
    const bp = pins && pins[b.cardid].checked ? 1 : -1;
    if (ap != bp) return ap > bp ? -1 : 1;
    if (at == bt) return a.name > b.name ? 1 : -1;
    return at > bt ? sorts[0] : sorts[1];
  });

  return (
    <>
      {isPC && (
        <div css={informaiton}>
          <p css={subtitle}>{cardNames.length}件</p>
          <div css={buttoArea}>
            {/*並び替え。どちらか一つがTrueになる状態*/}
            <button
              type="button"
              css={[toggleButton(sortType == SORT_TYPE_TOTAL_AMOUNT)]}
              onClick={() => setSortType(SORT_TYPE_TOTAL_AMOUNT)}
            >
              還元率が高い順
            </button>
            {/*
            <button
              type="button"
              css={[toggleButton(sortType == SORT_TYPE_ANNUAL_FEE)]}
              onClick={() => setSortType(SORT_TYPE_ANNUAL_FEE)}
            >
              年会費が安い順
            </button>
            */}
          </div>
        </div>
      )}
      {!isPC && isSubmitted && (
        <div css={informaitonNavWrapper}>
          <div css={informaitonNav}>
            <p css={subtitle}>{cardNames.length}件</p>
            <div css={navButtonArea}>
              <div css={navButtonItem}>
                <ImportExportIcon />
                <select
                  name="sort"
                  css={itemSelect}
                  onChange={(e) => setSortType(Number(e.target.value))}
                >
                  {/*
                  <option value={SORT_TYPE_TOTAL_AMOUNT} hidden>
                    並び替え
                  </option>*/}
                  <option value={SORT_TYPE_TOTAL_AMOUNT}>還元率が高い順</option>
                  {/*
                  <option value={SORT_TYPE_ANNUAL_FEE}>年会費が安い順</option>
                  */}
                </select>
              </div>

              <button
                css={navButtonItem}
                onClick={() => setIsTableView(!isTableView)}
              >
                {isTableView ? <TableChartIcon /> : <ViewModuleIcon />}
                表示
              </button>
            </div>
          </div>
          {/* 現在無効 : パソコンにURLを送る */}
          {false && isTableView && (
            <div css={modalWrapper}>
              <SendLinkModal />
            </div>
          )}
        </div>
      )}

      <div css={overlayWrapper}>
        {!isPC && !isSubmitted && (
          <div css={overlay}>
            <p css={overlayText}>＼ここからスタート！／</p>
            <SimulationModal
              button="primary"
              label="シミュレーションを開始する"
            />
          </div>
        )}
        {!isPC && !isTableView ? (
          <CardList scrollToCard={scrollToCard} />
        ) : (
          <Table scrollCardId={scrollCardId} />
        )}
      </div>
    </>
  );
};

const overlayWrapper = css`
  position: relative;
`;

const overlay = css`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  height: 100%;
  top: 0;
  left: 0;
  padding: 10vw 16px 0;
  width: 100%;
  z-index: 10;
  overflow: hidden;
`;

const overlayText = css`
  color: ${color.slate[0]};
  font-weight: bold;
  text-align: center;
`;

const subtitle = css`
  font-size: ${text.sm};
  font-weight: bold;
`;

const informaiton = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const informaitonNavWrapper = css`
  background: ${color.slate[50]};
  padding: 16px;

  ${mediaQueries("lg")} {
    display: none;
  } ;
`;

const informaitonNav = css`
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${mediaQueries("lg")} {
    display: none;
  } ;
`;

const buttoArea = css`
  display: flex;
  gap: 16px;
`;

const toggleButton = (isChecked: boolean) => css`
  display: none;

  ${mediaQueries("lg")} {
    border: ${isChecked ? "none" : "1px solid " + color.slate[300]};
    border-radius: 999px;
    background: ${isChecked ? color.edesuYellow[400] : color.slate[0]};
    color: ${color.slate[800]};
    display: block;
    font-size: ${text.sm};
    line-height: 1;
    padding: 12px;
    ${isChecked && "font-weight: bold"};
  } ;
`;

const navButtonArea = css`
  align-items: center;
  display: flex;
  gap: 12px;
`;

const navButtonItem = css`
  align-items: center;
  font-size: ${text.xs};
  display: flex;
  text-align: center;
  gap: 4px;
  padding: 2px;

  svg {
    height: ${text.base};
    width: ${text.base};
  }
`;

const itemSelect = css`
  text-align: left;
`;

const modalWrapper = css`
  justify-content: end;
  display: flex;
`;
