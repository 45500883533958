import { css } from "@emotion/react";
import Drawer from "@material-ui/core/Drawer";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { FC, useState } from "react";
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFormContext,
} from "react-hook-form";
import { DisableButton, PrimaryButton, SecondaryButton } from "../atoms/Button";
import { InputTypeNumber } from "../atoms/InputTypeNumber";
import { cardNames, FormValues, storeNames } from "../entrypoints/CreditCard";
import { amountFormat, calcTotalCardAmounts } from "../libs/Calculator";
import { margin } from "../styles/space";
import { color, text } from "../styles/theme";

type Props = {
  button: string;
  label: string;
};

export const SimulationModal: FC<Props> = ({ button, label }) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useFormContext<FormValues>();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const categories = watch("category");
  const totalCardAmounts = calcTotalCardAmounts(categories);

  const onValid: SubmitHandler<FormValues> = (_data) => {
    setOpenDrawer(false);
  };
  const onInvalid: SubmitErrorHandler<FormValues> = (data) => {
    console.log("Invalid form data.");
    console.log(data);
  };

  const contents = () => (
    <div role="presentation" css={pageWrapper}>
      <button
        css={swipeButton}
        onClick={() => {
          setOpenDrawer(false);
        }}
      >
        <div css={swipeHandle}></div>
      </button>

      <div css={margin.top[8]}>
        <div css={cardPoint}>
          <p css={cardItemTitle}>年間で貯まるポイント（上位5位）</p>
          <div css={cardPointBlock}>
            {cardNames.slice(0, 5).map((card, index) => (
              <div css={cardItem} key={index}>
                <img src={card.image} alt="" width={40} height={23} />
                <p css={cardItemPrice}>
                  <span css={cardItemNumber}>
                    {amountFormat(totalCardAmounts[card.cardid] || 0)}
                  </span>
                  円
                </p>
              </div>
            ))}
          </div>
        </div>

        <div css={listWrapper}>
          {storeNames.map((category, index) => (
            <details css={list} key={index}>
              <summary css={listTitle}>
                {category.category_name}
                <span css={listIcon} className="icon">
                  <KeyboardArrowDownIcon />
                </span>
              </summary>
              <div css={listBlock}>
                {category.stores.map((store, storeindex) => (
                  <div css={listItem} key={storeindex}>
                    <span css={listItemName}>{store.name}</span>
                    <div css={listItemText}>
                      <InputTypeNumber
                        register={register(
                          `category.${index}.stores.${storeindex}.userprice` as const,
                          {
                            valueAsNumber: true,
                            required: true,
                            pattern: /[0-9]*/,
                          }
                        )}
                        placeholder="10,000"
                      />
                      <p css={listItemUnit}>円/月</p>
                    </div>
                  </div>
                ))}
              </div>
            </details>
          ))}
        </div>
        {isDirty && isValid && (
          <PrimaryButton
            css={[buttonStyle, margin.top[16]]}
            onClick={() => handleSubmit(onValid, onInvalid)()}
          >
            シミュレーション結果へ
          </PrimaryButton>
        )}
        {(!isDirty || !isValid) && (
          <DisableButton css={[buttonStyle, margin.top[16]]}>
            シミュレーション結果へ
          </DisableButton>
        )}
      </div>
    </div>
  );

  return (
    <>
      {button === "primary" ? (
        <PrimaryButton
          css={[buttonStyle, margin.top[16]]}
          onClick={() => setOpenDrawer(true)}
        >
          {label}
        </PrimaryButton>
      ) : (
        <SecondaryButton
          css={[buttonStyle, margin.top[16]]}
          onClick={() => setOpenDrawer(true)}
        >
          {label}
        </SecondaryButton>
      )}

      <Drawer
        anchor="bottom"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        {contents()}
      </Drawer>
    </>
  );
};

const pageWrapper = css`
  padding: 0 16px;
  min-height: 80vh;
`;

const swipeButton = css`
  width: 100%;
`;

const swipeHandle = css`
  background: ${color.slate[300]};
  opacity: 0.15;
  border-radius: 4px;
  height: 4px;
  margin: 8px auto 24px;
  width: 40px;
`;

const cardPoint = css`
  background: ${color.slate[100]};
  border-radius: 4px;
  padding: 16px;
`;

const cardPointBlock = css`
  display: flex;
  justify-content: space-between;
  margin: 8px auto 0;
  max-width: 420px;
  text-align: center;
`;

const cardItem = css`
  text-align: center;
`;

const cardItemPrice = css`
  font-size: ${text.xs};
  margin-top: 4px;
`;

const cardItemTitle = css`
  font-size: ${text.base};
  font-weight: bold;
  text-align: center;
`;

const cardItemNumber = css`
  font-weight: bold;
`;

const list = css`
  font-weight: bold;

  &[open] .icon {
    transform: rotate(180deg);
  }
`;

const listWrapper = css`
  font-size: ${text.lg};
  margin-top: 16px;
  height: 46vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const listBlock = css`
  padding: 0 16px 16px;
`;

const listItem = css`
  align-items: center;
  font-size: ${text.sm};
  display: flex;
  justify-content: space-between;

  &:not(:first-of-type) {
    margin-top: 16px;
  }
`;

const listTitle = css`
  align-items: center;
  border-top: 1px solid ${color.slate[300]};
  display: flex;
  font-size: ${text.lg};
  font-weight: normal;
  justify-content: space-between;
  padding: 16px 0;
  gap: 8px;

  &::-webkit-details-marker {
    display: none;
  }
`;

const listIcon = css`
  transition: transform 0.4s;
`;

const listItemName = css`
  width: 48%;
  margin-right: 8px;
`;

const listItemText = css`
  align-items: center;
  display: flex;
  gap: 4px;
  text-align: right;
`;

const listItemUnit = css`
  width: 3rem;
`;

const buttonStyle = css`
  width: 100%;
`;
