import { css, SerializedStyles } from "@emotion/react";
import { FC } from "react";
import { UseFormRegisterReturn, UseFormWatch } from "react-hook-form";
import { FormValues } from "../entrypoints/CreditCard";

type Props = JSX.IntrinsicElements["input"] & {
  register: UseFormRegisterReturn;
  label?: string;
  checkWrapCss?: SerializedStyles;
};

export const Checkbox: FC<Props> = ({ id, register }) => {
  return <input type="checkbox" css={baseStyle} id={id} {...register} />;
};

const baseStyle = css`
  height: 16px;
  width: 16px;
`;
