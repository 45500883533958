export const color = {
  slate: {
    0: "#fff",
    50: "#f8fafc",
    100: "#f1f5f9",
    300: "#cbd5e1",
    500: "#64748b",
    800: "#1e293b",
  },
  edesuYellow: {
    200: "#ffe6aa",
    400: "#fbbf24",
    600: "#d97706",
  },
  blue: {
    600: "#2563eb",
  },
  red: {
    600: "#dc2626",
  },
  green: {
    600: "#16a34a",
  },
  divider: {
    onBackground: "rgba(255 255 255 / 0.2)",
    onSurface: "rgba(0 0 0 / 0.12)",
  },
  text: {
    disabled: "rgba(0 0 0 / 0.38)",
    mediumEmphasis: "rgba(0 0 0 / 0.6)",
    highEmphasis: "rgba(0 0 0 / 0.87)",
  },
  overlay: "rgba(0 0 0 / 0.7)",
};

export const text = {
  xs: ".75rem",
  sm: ".875rem",
  base: "1rem",
  lg: "1.125rem",
  xl: "1.25rem",
  "2xl": "1.5rem",
  "3xl": "1.875rem",
  "4xl": "2.25rem",
  "5xl": "3rem",
  "6xl": "3.75rem",
};

export const lineHeight = {
  s: 1.4,
  m: 1.6,
  l: 1.8,
};

export const elevation = {
  8: "0px 2px 8px rgba(0, 0, 0, 0.12)",
  16: "0px 4px 16px rgba(0, 0, 0, 0.08)",
};
