import { css } from "@emotion/react";
import { FC, ReactNode } from "react";
import { color, text } from "../styles/theme";

type Props = JSX.IntrinsicElements["button"] & {
  children: ReactNode;
  icon?: string;
};

export const PrimaryButton: FC<Props> = ({
  children,
  icon,
  onClick,
  ...props
}) => {
  const materialIcon = icon && <span css={iconStyle}>{icon}</span>;
  return (
    <button
      type="button"
      css={[baseStyle, primaryStyle]}
      onClick={onClick}
      {...props}
    >
      {children}
      {materialIcon}
    </button>
  );
};

export const SecondaryButton: FC<Props> = ({
  children,
  icon,
  onClick,
  ...props
}) => {
  const materialIcon = icon && <span css={iconStyle}>{icon}</span>;
  return (
    <button
      type="button"
      css={[baseStyle, secondaryStyle]}
      onClick={onClick}
      {...props}
    >
      {children}
      {materialIcon}
    </button>
  );
};

export const AlertButton: FC<Props> = ({
  children,
  icon,
  onClick,
  ...props
}) => {
  const materialIcon = icon && <span css={iconStyle}>{icon}</span>;
  return (
    <button
      type="button"
      css={[baseStyle, alertStyle]}
      onClick={onClick}
      {...props}
    >
      {children}
      {materialIcon}
    </button>
  );
};

export const DisableButton: FC<Props> = ({
  children,
  icon,
  onClick,
  ...props
}) => {
  const materialIcon = icon && <span css={iconStyle}>{icon}</span>;
  return (
    <button
      type="button"
      css={[baseStyle, disableStyle]}
      onClick={onClick}
      {...props}
    >
      {children}
      {materialIcon}
    </button>
  );
};

const baseStyle = css`
  align-items: center;
  border: 2px solid transparent;
  border-radius: 8px;
  display: flex;
  font-size: ${text.base};
  font-weight: 600;
  justify-content: center;
  padding: 8px;
`;

const primaryStyle = css`
  background-color: ${color.edesuYellow[400]};
  color: ${color.slate[800]};
`;

const secondaryStyle = css`
  background-color: ${color.slate[0]};
  border-color: ${color.slate[300]};
  color: ${color.slate[800]};
`;

const alertStyle = css`
  background-color: ${color.red[600]};
  color: ${color.slate[0]};
`;

const disableStyle = css`
  background-color: ${color.slate[500]};
  color: ${color.slate[0]};
`;

const iconStyle = css`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 8px;

  svg {
    font-size: 24px;
  }
`;
