import { FC } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Error404 } from "./pages/404";
import { Error500 } from "./pages/500";
import { Index } from "./pages/Index";

export const App: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`/`} element={<Index />} />
        <Route path={`404`} element={<Error404 />} />
        <Route path={`500`} element={<Error500 />} />
        <Route path={`*`} element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};
