import { css } from "@emotion/react";
import CloseIcon from "@material-ui/icons/Close";
import ShareIcon from "@material-ui/icons/Share";
import { FC, useState } from "react";
import Modal from "react-modal";
import { PrimaryButton } from "../atoms/Button";
import { margin } from "../styles/space";
import { color, text } from "../styles/theme";

const customStyles: Modal.Styles = {
  overlay: {
    background: color.overlay,
    position: "fixed",
    zIndex: 10,
  },
};

Modal.setAppElement("#root");

export const SendLinkModal: FC = () => {
  const [openFlag, setOpenFlag] = useState(false);

  return (
    <>
      <button type="button" css={sendLink} onClick={() => setOpenFlag(true)}>
        パソコンにURLを送る <ShareIcon />
      </button>
      <Modal
        isOpen={openFlag}
        css={modalStyle}
        style={customStyles}
        onRequestClose={() => {
          setOpenFlag(false);
        }}
      >
        <button css={closeButton} onClick={() => setOpenFlag(false)}>
          <CloseIcon />
        </button>
        <div css={modalBlock}>
          <h2 css={subtitle}>URLを送る</h2>
          <p css={urlText}>https://hogehoge.jp</p>
          <PrimaryButton css={[button, margin.top[16]]}>
            コピーする
          </PrimaryButton>
        </div>
      </Modal>
    </>
  );
};

const subtitle = css`
  font-size: ${text.xl};
  font-weight: bold;
`;

const modalStyle = css`
  background: ${color.slate[0]};
  border-radius: 8px;
  height: fit-content;
  inset: 16px;
  margin: auto;
  max-width: 480px;
  padding: 32px 16px;
  position: absolute;
`;

const modalBlock = css`
  font-size: ${text.sm};
`;

const closeButton = css`
  position: absolute;
  color: ${color.slate[0]};
  right: 0;
  top: -32px;
`;

const sendLink = css`
  align-items: center;
  color: ${color.blue[600]};
  display: flex;
  font-size: ${text.xs};
  gap: 4px;
  margin-top: 16px;
  justify-content: end;

  svg {
    height: ${text.base};
    width: ${text.base};
  }
`;

const button = css`
  margin-top: 32px;
  width: 100%;
`;

const urlText = css`
  border: 2px solid ${color.slate[300]};
  border-radius: 4px;
  display: block;
  margin-top: 16px;
  padding: 12px;
`;
