export const margin = {
  top: {
    0: { marginTop: 0 },
    2: { marginTop: 2 },
    4: { marginTop: 4 },
    8: { marginTop: 8 },
    12: { marginTop: 12 },
    16: { marginTop: 16 },
    24: { marginTop: 24 },
    32: { marginTop: 32 },
    40: { marginTop: 40 },
    48: { marginTop: 48 },
    56: { marginTop: 56 },
    64: { marginTop: 64 },
    72: { marginTop: 72 },
    80: { marginTop: 80 },
  },
  right: {
    0: { marginRight: 0 },
    2: { marginRight: 2 },
    4: { marginRight: 4 },
    8: { marginRight: 8 },
    12: { marginRight: 12 },
    16: { marginRight: 16 },
    24: { marginRight: 24 },
    32: { marginRight: 32 },
    40: { marginRight: 40 },
    48: { marginRight: 48 },
    56: { marginRight: 56 },
    64: { marginRight: 64 },
    72: { marginRight: 72 },
    80: { marginRight: 80 },
  },
  bottom: {
    0: { marginBottom: 0 },
    2: { marginBottom: 2 },
    4: { marginBottom: 4 },
    8: { marginBottom: 8 },
    12: { marginBottom: 12 },
    16: { marginBottom: 16 },
    24: { marginBottom: 24 },
    32: { marginBottom: 32 },
    40: { marginBottom: 40 },
    48: { marginBottom: 48 },
    56: { marginBottom: 56 },
    64: { marginBottom: 64 },
    72: { marginBottom: 72 },
    80: { marginBottom: 80 },
  },
  left: {
    0: { marginLeft: 0 },
    2: { marginLeft: 2 },
    4: { marginLeft: 4 },
    8: { marginLeft: 8 },
    12: { marginLeft: 12 },
    16: { marginLeft: 16 },
    24: { marginLeft: 24 },
    32: { marginLeft: 32 },
    40: { marginLeft: 40 },
    48: { marginLeft: 48 },
    56: { marginLeft: 56 },
    64: { marginLeft: 64 },
    72: { marginLeft: 72 },
    80: { marginLeft: 80 },
  },
};
